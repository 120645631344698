<template>
  <div>
    <v-row>
      <v-col class="mx-auto" cols="12"  md="10">
        <v-card
          class="mb-10"
          elevation="4"
          rounded="xl"
          min-width="200px"
        >
          <v-card-title class="text-overline">
            Historial de Compras
            <v-layout class="justify-end">
              <v-btn text @click="step = 1" class="mt-3">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn text color="blue" @click="step = 2" class="mt-3">
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-menu
                
                  rounded="xl"
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="datosFecha"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                   <v-row style="max-width: 300px;" class="px-4 mt-2">
                    <v-avatar tile color="#2f3861" class="rounded-lg">
                      <v-btn v-bind="attrs"
                      v-on="on" text dark>
                        <v-icon  dark>mdi-calendar</v-icon>
                      </v-btn>
                    </v-avatar>
                   
                   </v-row>
                  </template>
                  <v-date-picker
                    :events="arrayEvents"
                    event-color="green lighten-1"
                    v-model="datosFecha"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="!datosFecha"
                      text
                      color="primary"
                      @click="verProductosFecha(datosFecha)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-row justify="space-between" class="px-6 mt-5">
                  <span class="text-overline black--text">Productos </span>
                  <span class="text-overline red--text"
                    >Total: ${{ sumall }}</span
                  >
                </v-row>
                <div class="text-center" v-if="productos.length === 0">
                  El dia de Hoy no tienes Productos Encargados <br />
                  <v-btn to="/" text color="blue"> ir a Comprar </v-btn>
                </div>
                <v-row
                  class="px-3 mb-4"
                  v-for="(productos, index) in productos"
                  :key="index"
                >
                  <v-col cols="3">
                    <v-avatar tile size="100" class="rounded-xl">
                      <v-img :src="url + productos.imagen" />
                    </v-avatar>
                  </v-col>
                  <v-col xl="3" md="7" sm="10" class="mt-2">
                    <h4 class="blue--text">{{ productos.nombre }}</h4>
                    <h5>
                      Precio: {{ productos.precio }} &nbsp;&nbsp; Cantidad:
                      {{ productos.producto_cantidad }}
                    </h5>
                    <h4>Subtotal: {{ productos.sub_total }}</h4>
                    <h3 v-if="productos.estado == 'Activo'" class="green--text">
                      Estado: {{ productos.estado }}
                      🟢
                    </h3>
                    <h3
                      v-else-if="productos.estado == 'En Preparacion'"
                      class="orange--text"
                    >
                      Estado: {{ productos.estado }} 🟠
                      <a v-show="false" :href="url + productos.nota_compra"
                        >Factura</a
                      >
                    </h3>
                    <h3 v-else class="blue--text">
                      Estado: {{ productos.estado }} 🔵
                      <a v-show="false" :href="url + productos.nota_compra"
                        >Factura</a
                      >
                    </h3>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item :value="2">
                <h3 class="px-6 mb-5">
                  Estos son tus comprobantes de los productos en tu lista anterior, NO olvides
                  llevarlos en caso de recogerlos en nuestra tienda o bien puedes generar un solo codigo QR, una vez generado solo debes presentar ese codigo en nuestro establecimiento.
                </h3>
                <div class="text-center" v-if="comprobantes.length === 0">
                  Al parecer no tienes Pedidos Por Recojer :( <br />
                  <v-btn text color="blue" to="/"> ir a Comprar </v-btn>
                </div>

                <v-row
                  v-else
                  class="px-3 mb-4"
                  v-for="(show_comprobantes, index) in comprobantes"
                  :key="index"
                >
                  <v-col cols="3">
                    <v-avatar tile size="100">
                      <v-img src="@/assets/images/archivo-pdf.png" />
                    </v-avatar>
                  </v-col>
                  <v-col xl="3" md="7" sm="10" class="mt-2">
                    <h4 class="blue--text">
                      <a
                        :href="url + show_comprobantes.nota_compra"
                        class="text-h5"
                        >Imprimir Comprobante</a
                      >
                    </h4>
                    <br />
                    <h3 class="green--text">Estado: Activo 🟢</h3>

                  </v-col>

                </v-row>
                <div v-if="comprobantes.length >=1" class="text-right" ><v-btn to="/users/qrcode" rounded text outlined color="green">Generar QR &nbsp; <v-icon>mdi-qrcode</v-icon></v-btn></div>

              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
    
    <script>
import moment from "moment";

export default {
  data: () => ({
    step: 1,
    editarPerfil: false,
    enviarDatos: [],
    menu: false,
    arrayEvents: [],
    datosFecha: "",
    datosUsuario: [],
    productos: [],
    url: "",
    comprobantes: [],
  }),
  computed: {
    sumall() {
      const data = [];
      this.productos.forEach((element) => {
        data.push({ subtotal: element.precio * element.producto_cantidad });
      });
      const sumall = data
        .map((item) => item.subtotal)
        .reduce((prev, curr) => prev + curr, 0);

      return sumall;
    },
  },
  mounted() {},
  methods: {
    abrirEditar() {
      this.enviarDatos = this.datosUsuario;
      this.editarPerfil = true;
    },
    cerrarEditar() {
      this.editarPerfil = false;
      this.obtenerAcceso();
    },

    inicio() {
      this.$router.push("/");
    },
    cerrarSesion() {
      window.localStorage.clear();
      this.$router.push("/");
    },
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
   
    verProductos() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuarios`
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
        this.productos.forEach((element) => {
          this.arrayEvents.push(element.fecha);
        });
      });
    },
    verProductosFecha(item) {
      this.$refs.menu.save(item);
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,
        {
          fecha: item,
        },
        "POST"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
      });
    },
    obtenerComprobantes() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarComprobanteClientes`
      );
      peticion.then(({ datos }) => {
        this.comprobantes = datos;
      });
    },
    productosSinRecoger() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosSinRecojer`
      );
      peticion.then(({ datos }) => {
        this.productos = datos;
      });
    },

    verProductosFechaMounted() {
      const date = moment().format("YYYY-MM-DD");
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,
        {
          fecha: date,
        },
        "POST"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
      });
    },



    
  },

  created() {
    this.obtenerAcceso();
    this.verProductos();
   

    this.url = `${this.$store.state.imgURL}`;
    this.obtenerComprobantes();

    this.verProductosFechaMounted();
    this.productosSinRecoger()
  },
};
</script>
    <style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
.my-list {
  max-height: 380px; /* Altura máxima de la lista */
  overflow-y: auto; /* Scroll en el eje Y */
}
</style>
    