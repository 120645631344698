<template>
  <div>
    <v-app-bar height="100px" app class="card-color">
      <v-avatar class=" d-none d-md-block" tile size="120">
        <v-img
          @click="regresarInicio"
          src="@/assets/images/meraLogo.png"
          class="ml-7"
          contain
          width="90"
        ></v-img>
      </v-avatar>

      
      <v-menu  max-width="200px" open-on-hover rounded="xl" offset-y>
        <template  v-slot:activator="{ on, attrs }">
          <div
          v-if="datosUsuario === 'No Loggeado'" 
          >
            
          </div>
          <v-btn
          v-else 
              fab
            rounded
            class="d-sm-block d-md-none mx-5  text-overline"
           
            
            text
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
           <v-avatar  style="border: 3px solid #5fb051;" size="60">
            <v-img :src="url+datosUsuario.foto_perfil"></v-img>
           </v-avatar>
          </v-btn>
        </template>
        <v-card shaped>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="url+datosUsuario.foto_perfil"
                 
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  datosUsuario.nombre + " " + datosUsuario.apellidoP
                }}</v-list-item-title>
                <v-list-item-subtitle>Bienvenido!</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/users/editarPerfilUsuario">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Mi perfil</v-list-item-title>
            </v-list-item>
            <v-list-item to="/users/pedidosActivos" color="red">
              <v-list-item-icon>
                <v-icon>mdi-bag-personal-tag-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Pedidos</v-list-item-title>
            </v-list-item>
            <v-list-item to="/users/pedidosActivos" color="red">
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Historial Compras</v-list-item-title>
            </v-list-item>
            <v-list-item to="/users/soporte" color="red">
              <v-list-item-icon>
                <v-icon>mdi-lifebuoy</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Soporte</v-list-item-title>
            </v-list-item>
            <v-divider/>
            <v-list-item @click="cerrarSesion" link >
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
            
            
          </v-list>
        </v-card>
      </v-menu>
   



      <v-toolbar-title class="ml-4">
        <span class="white--text text-h5 d-none d-md-block"
          >Ingenieria
          <FONT class="green--text text-h5 font-weight-bold">M</FONT>
          <FONT class="blue--text text-h5 font-weight-bold">E</FONT>
          <FONT class="red--text text-h5 font-weight-bold">R</FONT>
          <FONT class="yellow--text text-h5 font-weight-bold">A</FONT>
        </span>

       
       


      
      </v-toolbar-title>

      <v-spacer> </v-spacer>
      
      <!-- carrito de compras -->
      <div v-if="datosUsuario === 'No Loggeado'"></div>
      <v-badge
        v-else
        color="green"
        overlap
        :content="cantidad"
        class="mr-8 mt-1"
      >
        <v-avatar color="#252e53" size="40" rounded="lg">
          <v-icon @click="abrirSideBar" color="blue"
            >fas fa-shopping-cart</v-icon
          >
        </v-avatar>
      </v-badge>

      <v-btn
        v-if="datosUsuario === 'No Loggeado'"
        to="/account/login"
        text
        color="green"
        
        >Log <strong> in &nbsp;</strong>
        <v-icon x-small color="blue">fa-right-to-bracket</v-icon>
      </v-btn>
      <div class="d-none d-lg-block" v-else>
        <v-avatar  size="40" class=" ml-2">
          <v-img :src="url+datosUsuario.foto_perfil"></v-img>
        </v-avatar>

        <v-menu max-width="200px" open-on-hover rounded="xl" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              class="text-overline"
              text
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <strong>
                {{ datosUsuario.nombre + " " + datosUsuario.apellidoP }}</strong
              >
            </v-btn>
          </template>
          <v-card shaped>
            <v-list dense>
              <v-list-item>
                <v-list-item-avatar>
                  <img
                    :src="url+datosUsuario.foto_perfil"
                   
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{
                    datosUsuario.nombre + " " + datosUsuario.apellidoP
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Bienvenido!</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/users/editarPerfilUsuario">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Mi perfil</v-list-item-title>
              </v-list-item>
              <v-list-item to="/users/pedidosActivos" color="red">
                <v-list-item-icon>
                  <v-icon>mdi-bag-personal-tag-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Pedidos</v-list-item-title>
              </v-list-item>
              <v-list-item to="/users/pedidosActivos" color="red">
                <v-list-item-icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Historial Compras</v-list-item-title>
              </v-list-item>
              <v-list-item to="/users/soporte" color="red">
                <v-list-item-icon>
                  <v-icon>mdi-lifebuoy</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Soporte</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item @click="cerrarSesion" link >
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
              
              
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
    <right-side-bar 
    @cerrarCarrito="cerrarCarrito"
    :drawer="drawer" @actualizarCantidad="actualizarCantidad" />
  </div>
</template>

<script>
import RightSideBar from "./RightSideBar.vue";

export default {
  components: { RightSideBar },
  data: () => {
    return {
      url:'',
      cantidad: 0,
      drawer: false,
      carrito: [],
      datosUsuario: [],
    };
  },

  computed: {},
  methods: {
    cerrarCarrito(){
          this.drawer = !this.drawer;
    },
    cerrarSesion() {
      window.localStorage.clear();
      location.reload()
    },
    actualizarCantidad() {
      this.cantidadCarrito();
    },
    regresarInicio() {
      this.$router.push("/");
    },
    abrirSideBar() {
      this.drawer = !this.drawer;
    },
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
    cantidadCarrito() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.cantidad = datos.datos.length;
      });
    },
  },
  created() {
    this.url = `${this.$store.state.imgURL}`;
    
    this.obtenerAcceso();
    this.cantidadCarrito();
  },
};
</script>

<style>
.v-toolbar__title {
  font-size: 1rem !important;
}
.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
</style>