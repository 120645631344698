<template>
  <v-item-group mandatory class="mt-n1">
    <v-container>
      <v-row justify="center" class="space">
        <v-col
          cols="12"
          xs="12"
          sm="4"
          md="2"
          v-for="(cetegory, i) in categories"
          :key="i"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'card-color' : 'card-color'"
              :class="active ? 'borderme' : 'borderout'"
              class="d-flex align-center rounded-lg mx-2"
              dark
              height="180"
              @click="toggle"
              flat
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item :to="cetegory.to" three-line class="text-center">
                    <v-list-item-content >
                      <div align="center" justify="center">
                        <v-img
                          :src="$store.state.imgURL+cetegory.img"
                          max-height="80"
                          max-width="80"
                          contain
                        ></v-img>
                      </div>
                      <v-list-item-subtitle
                        :class="active ? 'blue--text' : 'white--text'"
                        class="caption mt-4"
                        >{{ cetegory.title }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  data: () => ({
    categories: [
      { img: "pcbMera.png", title: "Modulos", to: "/categorias/Modulos" },
      { img: "lcdMera.png", title: "Desarrollo Y fabicacion",to: "/categorias/DesarrolloFabricacion" },
      { img: "motherboardMera.png", title: "Tarjetas de desarrollo",to: "/categorias/TarjetasDesarrollo"},
      { img: "productosPopulares.png", title: "Productos Populares",to: "/categorias/ProductosTop" },
      { img: "buscarProducto.png", title: "Buscar Producto",to: "/categorias/BusquedaProductos/producto" },

      
     
    ],
  }),
};
</script>

<style scoped>

.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
</style>