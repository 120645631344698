<template>
  <div>
    <v-card max-width="1000px" class="mx-auto rounded-xl">
      <v-card-text class="text-center">
        <h1 class="text-center mt-5" style="font-size: 60px">
          Soporte técnico de Mera
        </h1>
        <v-avatar size="100" class="mt-4" tile
          ><v-img src="@/assets/images/meraLogo.png"></v-img
        ></v-avatar>
      </v-card-text>

      <v-card-text class="mx-auto">
        <v-row>
          <v-col v-for="(show, index) in dataCards" :key="index">
            <v-card
              rounded="xl"
              class="mx-auto text-center mt-n5"
              width="300px"
              height="150px"
            >
              <span
                ><h4 style="font-size: 15px">{{ show.title }}</h4></span
              >

              <v-avatar size="100">
                <v-icon :color="show.color" size="80">{{ show.icon }}</v-icon>
              </v-avatar>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="7"
            ><v-card rounded="xl"
              ><h3>Soporte Tecnico</h3>

              <v-card-text
                >Bríndanos algunos detalles y te ofreceremos la mejor solución.
                Puedes comunicarte por teléfono, chat, correo electrónico y
                mucho más.</v-card-text
              >
              <v-img src="https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?w=740&t=st=1684605808~exp=1684606408~hmac=f2e882166a194bace1c2f1fc38f872c065cdc4f8e72025cbde73d288cfe28882"></v-img>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card rounded="xl"><h3>Problemas con algun Producto?</h3>

              <v-card-text
                >Bríndanos algunos detalles y te ofreceremos la mejor solución.
                Puedes comunicarte por teléfono, chat, correo electrónico y
                mucho más.</v-card-text>

                <v-img src="https://img.freepik.com/free-vector/bundle-engineering-set-icons_24877-57315.jpg?w=826&t=st=1684605898~exp=1684606498~hmac=ab45cae7f267f97141e87d84fd1860c6d3e41f4e12b08cfaf8f0c10dc4a51c2a"></v-img>
            </v-card></v-col
          >
        </v-row>
      </v-card-text>
      <v-divider class="ml-16 mr-16"></v-divider>

            <v-card-text class="mx-auto text-center">
            <v-btn class="mx-2"
            v-for="(button,index) in socialButtons" :key="index"
            fab text outlined >
                <v-icon size="50">
                    {{ button.icon }}
                </v-icon>
            </v-btn>

            </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      dataCards: [
        { title: "Cambio de Contraseña", icon: "mdi-lock-reset",color:'green' },
        {
          title: "Ayuda Para mis Proyectos",
          icon: "mdi-lifebuoy",color:'red'
        },

        { title: "Contacto", icon: "mdi-contacts",color:'blue' },

      ],
      socialButtons:[{icon: "mdi-facebook"},
      {icon: "fab fa-tiktok"},
      {icon: "mdi-twitter"},
      {icon: "mdi-instagram"},]
    };
  },
  methods: {},
};
</script>