<template>
  
      
        
          <Login class="mt-10"/>


  </template>
  
  <script>
import Login from '../components/Login.vue'
    
  
    export default {
  components: {  Login },
     data: () => ({
    }),
      methods:{
        inicio(){
          this.$router.push('/') 
        }
      }
    }
  </script>
  <style scoped>
 
  .v-application .rounded-bl-xl {
      border-bottom-left-radius: 300px !important;
  }
  .v-application .rounded-br-xl {
      border-bottom-right-radius: 300px !important;
  }
  </style>
  