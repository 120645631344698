<template>
  <div style="overflow-x: hidden;" class="">
    <div v-if="datosUsuario === 'No Loggeado'">
      <v-dialog
      class="mx-auto"
        v-model="dialog"
        persistent
        transition="dialog-top-transition"
        max-width="400"
      >
        <v-card rounded="xl">
          <v-toolbar color="blue" dark
            >Bienvenido a Nuestra Tienda Online</v-toolbar
          >
          <v-card-text>
            <div class="text-h2 mt-10">Antes de Continuar!</div>
            <div class="text-h7 ">
              ¡Te damos la Bienvenida!. Crear una cuenta en nuestro sistema es
              fácil y rápido. Regístrate hoy mismo para acceder a todas las
              funcionalidades que tenemos para ofrecerte, desde una experiencia
              personalizada hasta descuentos exclusivos en nuestros productos y
              servicios. No esperes más para formar parte de nuestra comunidad,
              ¡crea tu cuenta ahora y comienza a disfrutar de todos los
              beneficios!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Mas Tarde</v-btn>
            <v-btn color="blue" text to="/account/login"
              >Crear Cuenta
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="mt-4 color-barraSuperior">
      <v-card-title>
        <h3 class="white--text mx-5">
          Empiza a comprar! <br />
          <strong class="text-h6 grey--text"
            >Tan sencillo como dar unos clicks!</strong
          >
        </h3>
        <v-spacer />
        <v-btn
        dark
          text
          small
          v-for="(button, i) in categories"
          :key="i"
          rounded
          :to="button.to"
        >
          <v-avatar tile size="30">
            <v-img :src="button.img"></v-img>
          </v-avatar>
          {{ button.title }}
        </v-btn>
      </v-card-title>
    </div>
    <v-card-text class="mx-10">
      <v-sheet

        rounded="xl"
        class="card-color mx-auto mr-16"
        elevation="4"
        max-width="1200"
      >
        <v-slide-group dark class="pa-4" active-class="success" show-arrows>
          <v-slide-item v-for="(sub, index) in items" :key="index">
            <v-card
              rounded="lg"
              class="card-color text-center ma-4 text-container"
              height="80"
              width="80"
            >
              <v-row class="fill-height" align="center" justify="center">
                <v-btn
                  @click="verProductos(sub)"
                  fab
                  color="blue"
                  small
                  outlined
                >
                  <v-avatar tile size="30">
                    <v-img
                      src="https://cdn-icons-png.flaticon.com/512/3896/3896332.png"
                    ></v-img>
                  </v-avatar>
                </v-btn>
              </v-row>
              <span class="mx-2">{{ sub.titulo }}</span>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-card-text>

    <v-card-text class="d-none d-md-block  mx-10">
      <v-card-title class="" >
        <v-avatar color="#bcd5ef" rounded="lg">
          <v-icon color="blue">mdi-cart</v-icon> </v-avatar
        >&nbsp; &nbsp;&nbsp;
        <h4  class="white--text">
         {{ products.length }} Productos Disponibles! <br />
          <strong class="text-h5 grey--text"
            >¡que esperas para comprar!
            <v-icon color="blue">mdi-robot-happy</v-icon></strong
          >
        </h4>
        <v-spacer> </v-spacer>
     
      </v-card-title>

     

     
    </v-card-text>

    <v-card-text class="d-sm-block d-md-none">
       <v-card-title class="d-sm-block d-md-none">
      <span class="ml-4 white--text">{{products.length}} Productos Disponibles!</span> <br>

        <span class="grey--text">¡que esperas para comprar!</span> <v-icon color="blue">mdi-robot-happy</v-icon>
       
       
     
      </v-card-title>
    </v-card-text>


    <v-card-text>
      <v-container rounded="xl" class="mx-auto" elevation="8">
        <v-slide-group dark class="pa-4" active-class="success" show-arrows>
          <v-slide-item v-for="(productos, index) in products" :key="index">
            <v-card
            
              rounded="xl"
              class=" card-color text-left ma-4 text-container-card"
              height="auto"
              width="250"
            >
              <v-img height="125" :src="url + productos.imagen"></v-img>
              <v-toolbar color="transparent" class="mt-n7" flat>
                <v-avatar tile size="90">
                  <v-img src="@/assets/images/meraLogo.png"></v-img>
                </v-avatar>
                <v-spacer></v-spacer>
                <v-avatar size="auto" class="mr-2 rounded-xl" tile>
                  <div class="five">
                    <span class="white--text text-h6 text-center"
                      >$ {{ productos.precio }}</span
                    >
                  </div>
                </v-avatar>
              </v-toolbar>
              <v-card-text>
                <span class="white--text text-left">{{ productos.categoria }}</span>
                <h2 class="blue--text text-container-card">
                  {{ productos.nombre }}
                </h2>
                <h3 class="text-left green--text">
                  $ {{ productos.precio }} <span>C/u</span>
                </h3>
                <h4 class="text-left blue--text">
                  {{ productos.stock }} Disponibles
                </h4>

                <v-card-actions class="justify-end">
                  <v-btn
                    :disabled="
                      productos.stock <= 0 || datosUsuario === 'No Loggeado'
                    "
                    @click="addCarrito(productos)"
                    class="blue"
                    fab
                    dark
                    small
                    color="blue"
                  >
                    <v-icon dark> mdi-cart </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-container>
    </v-card-text>
    <Footer></Footer>
  </div>
  </template>
  <script>
  export default {
    data: () => ({
      categories: [
      {
        img: "https://cdn-icons-png.flaticon.com/512/3967/3967882.png",
        title: "Modulos",
        to: "/categorias/Modulos",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/1998/1998614.png",
        title: "Desarrollo Y fabicacion",
        to: "/categorias/DesarrolloFabricacion",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/5921/5921800.png",
        title: "Tarjetas de desarrollo",
        to: "/categorias/TarjetasDesarrollo",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/3896/3896332.png",
        title: "Productos Populares",
        to: "/categorias/ProductosTop",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/3875/3875911.png",
        title: "Buscar Producto",
        to: "/categorias/BusquedaProductos/alberto",
      },
    ],
      menu:false,
      datosUsuario:[],
      url: "",
      selectedItem: 1,
      items: [
        {titulo: 'Arduinos', palabra: 'arduin'},
        {titulo: 'Capacitor', palabra: 'capacitor'},
        {titulo: 'Resistencias', palabra: 'resistencia'},
        {titulo: 'Leds', palabra: 'led'},
        {titulo: 'Transistores', palabra: 'transistor'},
        {titulo: 'Sensores', palabra: 'sensor'}
    ],
      products: [],
    }),
  
    computed:{
     
    },
    methods: {
      addCarrito(item){
        const peticion = this.enviarPeticionAPI(`${this.$store.state.apiURL}crearCarrito`,
        {
         producto_id: item.id,
         cantidad: 1
        },
        'POST'
        );
                  peticion.then(({datos}) => {
                      datos
                      this.mostrarMensajeConfirmacion('Producto Agregadoo al Carrito',)
                      this.$emit('mostrarMensaje'); 
                  });
  
      },
      obtenerAcceso(){
        const peticion = this.enviarPeticionAPI(`${this.$store.state.apiURL}accesoUsuarioTienda`);
                  peticion.then((datos) => {
                      this.datosUsuario = datos.datos;
                  });
      },
      
      verProductos(item) {
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}agruparProductos`,
          { palabra: item.palabra },
          "POST"
        );
        peticion.then((datos) => {
          //this.mostrarMensajeConfirmacion(mensaje);
          this.products = datos.datos;
        });
      },
    },
    created() {
      
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}agruparProductos`,
          { palabra: 'capacitor' },
          "POST"
        );
        peticion.then((datos) => {
          //this.mostrarMensajeConfirmacion(mensaje);
          this.products = datos.datos;
        });
    
      this.obtenerAcceso();
      this.url = `${this.$store.state.imgURL}`;
    
    },
  };
  </script>
  
<style scoped>
.color-barraSuperior {
  background-color: #252e53;
}

.five {
  width: 100%;
  height: 50%;
  background: #1f8323;
}
.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
.text-container {
  /* El ancho máximo del contenedor */
  white-space: nowrap; /* Evita el salto de línea */
  /* Evita el salto de línea */
  overflow: hidden; /* Oculta el texto que no cabe */
  text-overflow: ellipsis; /* Agrega los tres puntos al final */
}
.text-container-card {
  /* El ancho máximo del contenedor */
  /* Evita el salto de línea */
  /* Evita el salto de línea */
  overflow: hidden; /* Oculta el texto que no cabe */
  text-overflow: ellipsis; /* Agrega los tres puntos al final */
}
</style>