<template>
  <v-navigation-drawer v-model="drawer" app width="410" right>
    
    <v-btn 
    color="black"
    dark
    class="mt-2 ml-4"
    @click="$emit('cerrarCarrito')"
    rounded>
   Regresar
    </v-btn> <br>
    
    <p class="ma-2 mb-6 mt-8 text-button text-center" text large color="black">
      Tu carrito
      <v-btn
        :disabled="itemsCarrito == 0"
        @click="abrirVentana"
        color="blue"
        rounded
        dark
        class="white--text changewith"
      >
        Ir a pagar &nbsp; <v-icon> fa-pager </v-icon>
      </v-btn>
    </p>

    <v-card outlined elevation="2" class="rounded-xl mx-4">
      <v-card-subtitle class="black--text text-center text-overline">
       
        <v-chip class="text-overline" color="black" dark>
          Total: $ {{ sumall }}
        </v-chip>
      </v-card-subtitle>
      <div
        class="px-10 dark--text text-overline"
        v-if="itemsCarrito.length === 0"
      >
        Al parcer aun no Tienes Productos! :(
      </div>
      <v-card-text v-else>
        <v-card
          v-for="(producto, index) in itemsCarrito"
          :key="index"
          class="mx-auto rounded-xl mb-5"
          max-width="344"
          outlined
        >
          <v-btn
            @click="eliminarProducto(producto)"
            dark
            x-small
            fab
            color="red"
            absolute
            right
            class="mt-n5 mr-n8"
          >
            <v-icon> mdi-delete-empty </v-icon>
          </v-btn>
          <v-row>
            <v-col>
              <v-avatar class="rounded-xl" tile size="160">
                <v-img :src="url + producto.imagen"> </v-img>
              </v-avatar>
            </v-col>
            <v-col class=" ml-n5">
              <p
                class="text-caption text-center mt-1 mr-3"
                style="
                  border: solid 1px transparent;
                  border-radius: 10px;
                  background-color: #eceff1;
                "
              >
                {{ producto.nombre }}
              </p>
              <p
                class="text-caption text-center mt-1 mr-3"
                style="
                  font-weight: 700;
                  border: solid 1px transparent;
                  border-radius: 10px;
                  background-color: #eceff1;
                "
              >
                Precio: $ {{ producto.precio }}
              </p>
              <p
                class="text-caption text-center mt-1 mr-3"
                style="
                  border: solid 1px transparent;
                  border-radius: 10px;
                  background-color: #eceff1;
                "
              >
                {{ producto.stock }} Disponibles
              </p>
              <v-btn
                :disabled="producto.cantidad == 1"
                @click="decremento(producto)"
                fab
                x-small
              >
                <v-icon color="red">
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-chip color="white" class="elevation-4 ml-2 mr-2">
                {{ producto.cantidad }}
              </v-chip>
              <v-btn
                :disabled="producto.cantidad >= producto.stock"
                @click="incremento(producto)"
                fab
                x-small
              >
                <v-icon color="green">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <metodopago
      :ventana="metodoPago"
      @closeAddWindow="closeAddWindow"
    ></metodopago>
  </v-navigation-drawer>
</template>

<script>
import Swal from "sweetalert2";
import metodopago from "./MetodoPago.vue";

export default {
  components: { metodopago },
  data: () => ({
    datosCarro: [],
    metodoPago: false,
    mostrarTexfield: false,
    selectedItem: 0,
    url: "",
    items: [
      { icon: "mdi-home-outline" },
      { icon: "mdi-cart-outline" },
      { icon: "mdi-store-outline" },
      { icon: "mdi-calendar-check-outline" },
      { icon: "mdi-apps" },
    ],
    itemsCarrito: [],
  }),
  props: ["drawer","cantidad"],
  watch: {

    cantidad(value) {
      this.obtenerCarrito();
      return value;
    },
    drawer(value) {
      this.obtenerCarrito();
      return value;
    },
  },
  computed: {
    sumall() {
      const data = [];
      this.itemsCarrito.forEach((element) => {
        data.push({ subtotal: element.precio * element.cantidad });
      });
      const sumall = data
        .map((item) => item.subtotal)
        .reduce((prev, curr) => prev + curr, 0);

      return sumall;
    },
  },
  methods: {
    abrirVentana() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.datosCarro = datos.datos;
      });
      this.metodoPago = true;
    },
    closeAddWindow() {
      this.metodoPago = false;
    },
    decremento(item) {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}actualizarCarrito`,
        { id: item.carrito_id, cantidad: item.cantidad-- },
        "PUT"
      );
      peticion.then((datos) => {
       
       datos
          this.obtenerCarrito()
      });
    },
    incremento(item) {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}actualizarCarrito`,
        { id: item.carrito_id, cantidad: item.cantidad++ },
        "PUT"
      );
      peticion.then((datos) => {
        datos
          this.obtenerCarrito()
      });
    },

    eliminarProducto(item) {
      Swal.fire({
        title: "Estas Seguro?",
        text: `Eliminaras este Producto: ${item.nombre}  de tu Carrito!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
         cancelButtonText: "Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          const peticion = this.enviarPeticionAPI(
            `${this.$store.state.apiURL}eliminarCarrito`,
            { carrito_id: item.carrito_id },
            "DELETE"
          );
          peticion.then((menssage) => {
            menssage;
            this.mostrarMensajeConfirmacion("Producto Eliminado de tu Carrito");
            this.obtenerCarrito();
            this.$emit('actualizarCantidad')
          });
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },


    

    obtenerCarrito() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.itemsCarrito = datos.datos;
        
      });
    },
  },
  created() {
    this.obtenerCarrito();
    this.url = `${this.$store.state.imgURL}`;
   
  },
};
</script>

<style>
.border {
  margin: 0px 8px;
  background: #6f0dff;
  border-radius: 15px;
  text-decoration: none;
  width: 60px;
  height: 60px;
}
.v-list-item-group .v-list-item--active {
  color: white !important;
}
.v-list-item__content {
  padding: 20px 0 !important;
}
.image {
  border: 1px solid white;
}
.centered {
  position: absolute;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
