<template>
  <div style="overflow-x: hidden;">
    <div v-if="datosUsuario === 'No Loggeado'">
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card rounded="xl">
          <v-toolbar color="blue" dark
            >Bienvenido a Nuestra Tienda Online</v-toolbar
          >
          <v-card-text>
            <div class="text-h2 mt-10">Antes de Continuar!</div>
            <div class="text-h7 pa-12">
              ¡Te damos la Bienvenida!. Crear una cuenta en nuestro sistema es
              fácil y rápido. Regístrate hoy mismo para acceder a todas las
              funcionalidades que tenemos para ofrecerte, desde una experiencia
              personalizada hasta descuentos exclusivos en nuestros productos y
              servicios. No esperes más para formar parte de nuestra comunidad,
              ¡crea tu cuenta ahora y comienza a disfrutar de todos los
              beneficios!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Mas Tarde</v-btn>
            <v-btn color="blue" text to="/account/login"
              >Crear Cuenta
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>  <div class="mt-4 color-barraSuperior">
      <v-card-title>
        <h3 class="white--text mx-5">
          Empiza a comprar! <br />
          <strong class="text-h6 grey--text"
            >Tan sencillo como dar unos clicks!</strong
          >
        </h3>
        <v-spacer />
        <v-btn
        dark
          text
          small
          v-for="(button, i) in categories"
          :key="i"
          rounded
          :to="button.to"
        >
          <v-avatar tile size="30">
            <v-img :src="button.img"></v-img>
          </v-avatar>
          {{ button.title }}
        </v-btn>
      </v-card-title>
    </div>
    <div class="mt-10 mx-auto mb-10">
      <v-row>
        <v-col class="mx-auto">
          <v-card
            dark
            elevation="4"
            width="1000px"
            class="color-barraSuperior mx-auto text-center rounded-xl"
          >
            <v-layout justify-space-around>
              <v-card-title class="text-left">
                Resultado de Busqueda
              </v-card-title>
            </v-layout>
            <v-card-text class="d-none d-md-block  text-left">
              <v-row class="px-10">
                <h3 class="mt-4">
                  Hay {{ products.length }} Productos Relacionados a
                  {{ palabraClave }}
                </h3>
                <v-spacer> </v-spacer>
                <v-text-field
                  v-model="palabraClave"
                  @keyup="buscarProducto"
                  id="search"
                  dense
                  class="buscador d-flex d-sm-flex mr-10"
                  label="Buscar Producto"
                  filled
                  prepend-inner-icon="mdi-magnify"
                  solo
                  flat
                  background-color="transparent"
                  rounded
                  outlined
                />
              </v-row>
              
              
            </v-card-text>
           <v-card-text class="d-sm-block d-md-none">
            <v-text-field
            v-model="palabraClave"
            @keyup="buscarProducto"
            outlined
            rounded
            class="mx-10 mt-n8 "
            dense
            label="Nombre Modelo"
            prepend-inner-icon="mdi-magnify"
            >

            </v-text-field>
            <h3 class="mt-n4 mb-5">
              Hay {{ products.length }} Productos Relacionados a
              {{ palabraClave }}
            </h3>
           </v-card-text>

            
            <v-card-text class="mt-n10 text-left px-11">
              <small
                >Recuerda Buscar por el nombre correcto del componente que
                buscas para un mejor resultado, O simplemente busca el modelo Ej."74LS86"</small
              ></v-card-text
            >
            <v-card-text>
              <v-slide-group dark class="pa-4" active-class="success" show-arrows>
                <v-slide-item v-for="(productos, index) in products" :key="index">
                  <v-card
                  
                    rounded="xl"
                    class=" card-color text-left ma-4 text-container-card"
                    height="auto"
                    width="250"
                  >
                    <v-img height="125" :src="url + productos.imagen"></v-img>
                    <v-toolbar color="transparent" class="mt-n7" flat>
                      <v-avatar tile size="90">
                        <v-img src="@/assets/images/meraLogo.png"></v-img>
                      </v-avatar>
                      <v-spacer></v-spacer>
                      <v-avatar size="auto" class="mr-2 rounded-xl" tile>
                        <div class="five">
                          <span class="white--text text-h6 text-center"
                            >$ {{ productos.precio }}</span
                          >
                        </div>
                      </v-avatar>
                    </v-toolbar>
                    <v-card-text>
                      <span class="white--text text-left">{{ productos.categoria }}</span>
                      <h2 class="blue--text text-container-card">
                        {{ productos.nombre }}
                      </h2>
                      <h3 class="text-left green--text">
                        $ {{ productos.precio }} <span>C/u</span>
                      </h3>
                      <h4 class="text-left blue--text">
                        {{ productos.stock }} Disponibles
                      </h4>
      
                      <v-card-actions class="justify-end">
                        <v-btn
                          :disabled="
                            productos.stock <= 0 || datosUsuario === 'No Loggeado'
                          "
                          @click="addCarrito(productos)"
                          class="blue"
                          fab
                          dark
                          small
                          color="blue"
                        >
                          <v-icon dark> mdi-cart </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <Footer></Footer>
  </div>
</template>
  <script>
import Footer from '../components/Footer.vue';
export default {
  components: { Footer },
  data: () => ({
    dialog:true,
    menu: false,
    datosUsuario: [],
    url: "",
    selectedItem: 1,
    items: [],
    products: [],
    palabraClave: "",
    categories: [
      {
        img: "https://cdn-icons-png.flaticon.com/512/3967/3967882.png",
        title: "Modulos",
        to: "/categorias/Modulos",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/1998/1998614.png",
        title: "Desarrollo Y fabicacion",
        to: "/categorias/DesarrolloFabricacion",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/5921/5921800.png",
        title: "Tarjetas de desarrollo",
        to: "/categorias/TarjetasDesarrollo",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/3896/3896332.png",
        title: "Productos Populares",
        to: "/categorias/ProductosTop",
      },
      {
        img: "https://cdn-icons-png.flaticon.com/512/3875/3875911.png",
        title: "Buscar Producto",
        to: "/categorias/BusquedaProductos/alberto",
      },
    ],
  }),

  computed: {},
  methods: {
    addCarrito(item) {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}crearCarrito`,
        {
          producto_id: item.id,
          cantidad: 1,
        },
        "POST"
      );
      peticion.then(({ datos }) => {
        datos
        this.mostrarMensajeConfirmacion("Producto Agregado al Carrito");
        this.$emit("mostrarMensaje");
      });
    },
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },

    verProductos() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ProductosSubcategoria`,
        { id: 17 },
        "POST"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.products = datos.datos;
        this.menu = false;
      });
    },
    categoriasModulos() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}CategoriasTarjetasD`
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.items = datos.datos;
      });
    },
    buscarProducto() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}SearchRealTime`,
        { palabra_clave: this.palabraClave },
        "POST"
      );
      peticion.then((datos) => {
        this.products = datos.datos;
      });
    },
  },
  created() {
    this.verProductos();
    this.obtenerAcceso();
    this.categoriasModulos();
    this.url = `${this.$store.state.imgURL}`;
  },
};
</script>
  
  <style scoped>

/*div{
      display:inline-block;
      float:left;
      color:#fff;
      font-size:10px;
    }*/



.five {
  width: 100%;
  height: 50%;
  background: #1f8323;
}

.buscador {
  width: 1px;
}






.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
.color-barraSuperior {
  background-color: #252e53;
}

</style>