<template>
  <v-footer  dark padless>
    <v-card flat tile class="black white--text py-12 px-5" width="100%">
      <v-row class="justify-center ">
     
        <v-col >
          <v-card-text class="white--text pt-0">
            <h3>NUESTRA EMPRESA</h3>
          </v-card-text>
          <v-card-text class="grey--text"> Envios</v-card-text>
          <v-card-text class="grey--text mt-n4"> Aviso de Privacidad </v-card-text>
          <v-card-text class="grey--text mt-n4"> Terminos y condiciones de uso </v-card-text>
          <v-card-text class="grey--text mt-n4"> Nosotros </v-card-text>
          <v-card-text class="grey--text mt-n4"> Establecimientos </v-card-text>
        </v-col>
        <v-col class="text-center" >
          <v-card-text class="pt-2">
            <v-btn
            large
                v-for="(item, index) in icons" :key="index"
              class="white--text mr-5 ml-2"
                :color="item.color"
              fab
              outlined
              small
             :href="item.link"
            >
              <v-icon size="20" >
               {{ item.icon }}
              </v-icon>
              
            </v-btn>
            
          </v-card-text>
        </v-col>
        <v-col class="text-right" >
          <v-card-text class="white--text pt-0">
            <h3>INFROMACION DE LA TIENDA</h3>
          </v-card-text>
          <v-card-text class="grey--text">  Mera Atlacomulco </v-card-text>
          <v-card-text class="grey--text mt-n4">
            50450 Atlacomulco
                    </v-card-text>
          <v-card-text class="grey--text mt-n4">  Llámanos: 7121614845  </v-card-text>
          <v-card-text class="grey--text mt-n4"> Envíanos un correo electrónico: </v-card-text>
          <v-card-text class="grey--text mt-n4">  electronica@negociosmera.com  </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      {icon:"fab fa-tiktok", link: "https://www.tiktok.com/@ingenieriamera",color:'white'},
      {icon:"fab fa-instagram", link: "https://www.instagram.com/oficialmera/",color:'pink'},
      {icon:"fab fa-twitter", link: "https://twitter.com/mera_atlaco",color:'blue'},
      {icon:"fab fa-facebook-f", link: "https://www.facebook.com/mera.atlacomulco",color:'blue'},
    
    ],
  }),
};
</script>

<style>
</style>