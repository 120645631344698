
<template>
  <v-dialog width="auto" persistent v-model="metodoPago">
    <v-stepper
      class="mx-auto"
      max-width="880px"
      rounded="xl"
      v-model="e6"
      vertical
    >
      <v-stepper-step :complete="e6 > 1" step="1">
        Informacion Personal
        <small>Este paso es Necesario</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card rounded="xl"  color="#dbecfc" class="elevation-2 mt-5" height="200px">
          <v-row>
            <v-col>
              <v-radio-group v-model="datosUsuario.genero" class="px-5" row>
                <v-radio label="Hombre" color="blue" value="Hombre"></v-radio>
                <v-radio label="Mujer" color="pink" value="Mujer"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.nombre"
                readonly
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-account"
                label="Nombre"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.apellidoP"
                readonly
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-account"
                label="Apellido Paterno "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="datosUsuario.apellidoM"
                readonly
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-account"
                label="Apellido Materno"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.telefono"
                
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-cellphone-message"
                label="Telefono"
              />
            </v-col>
            <v-col>
              <v-text-field
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-email-alert-outline"
                label="correo Electronico"
                readonly
                v-model="datosUsuario.correo_electronico"
              />
            </v-col>
          </v-row>
        </v-card>

        <v-btn
          class="mt-4"
          :disabled="!datosUsuario.genero || !datosUsuario.telefono"
          rounded
          dark
          color="blue"
          @click="e6 = 2"
        >
          Continuar
        </v-btn>
        <v-btn class="mt-4" rounded color="red" text  @click="cerrarVentana"> Cancelar </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2"> Dirección </v-stepper-step>

      <v-stepper-content step="2">
        <v-card rounded="xl" color="#dbecfc" class="mt-5" height="200px">
          <v-row>
            <v-col>
              <v-text-field
                append-icon="mdi-flag"
                readonly
                rounded
                dense
                outlined
                class="mx-4 mt-5"
                label="Pais"
                v-model="datosUsuario.pais"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.estado"
                readonly
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-flag"
                label="Estado"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.municipio"
              readonly
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-home-silo-outline"
                label="Municipio"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="datosUsuario.codigo_postal"
                rounded
                readonly
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-numeric"
                label="Codigo Postal"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="datosUsuario.direccion_referencia"
                rounded
                outlined
                dense
                class="mt-5 px-4"
                append-icon="mdi-directions-fork"
                label="Direccion (Calle,Numero,Referencia) "
              />
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          :disabled="
            !datosUsuario.municipio ||
            !datosUsuario.codigo_postal ||
            !datosUsuario.direccion_referencia
          "
          rounded
          class="mt-5"
          color="blue"
          dark
          @click="e6 = 3"
        >
          Continuar
        </v-btn>
        <v-btn class="mt-5" color="red" text @click="cerrarVentana"> Cancelar </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Seleccion tu tipo de envio
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card
          max-width="400px"
          rounded="xl"
          color="#dbecfc"
          class="mt-5 mx-auto"
          min-height="auto"
        >
          <v-radio-group column v-model="datosUsuario.tipo_envio" class="mx-4">
            <template v-slot:label>
              <div>
                Selecciona una de
                <strong style="color: #1e88e5">las siguientes opciones</strong>
                (click al icono para ver sus opciones)
              </div>
            </template>
            <v-radio color="green" value="Envio Gratis">
              <template v-slot:label>
                <div class="ml-16">
                  <v-card
                    color="transparent"
                    elevation="0"
                    width="auto"
                    height="100px"
                  >
                    <v-row>
                      <v-col>
                        <v-tooltip bottom color="green">
                          <template v-slot:activator="{ on, attrs }">
                            <p v-bind="attrs" v-on="on" class="pt-7">
                              <v-icon>mdi-office-building-marker-outline</v-icon
                              >&nbsp; Mera Atlacomulco
                            </p>
                          </template>
                          <span
                            >De esta manera recojes tu pedido en nuestra tienda
                            fisica. Ubicada en Atlacomulco sin costos
                            adicionales!
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </template>
            </v-radio>
            <!-- <v-radio class="mt-n6" color="warning" value="250">
              <template v-slot:label>
                <div class="ml-16">
                  <v-card
                    color="transparent"
                    elevation="0"
                    width="auto"
                    height="100px"
                  >
                    <v-row>
                      <v-col>
                        <v-tooltip bottom color="warning">
                          <template v-slot:activator="{ on, attrs }">
                            <p v-bind="attrs" v-on="on" class="pt-7">
                              <v-icon>mdi-truck-check-outline</v-icon>&nbsp;
                              Estafeta
                            </p>
                          </template>
                          <span
                            >Llama o envia un whatsapp al 7121978209 para
                            verificar cobertura y tiempo de entrega. Solo
                            República Mexicana. <br />
                            El costo de envio es: $250.00 sin IVA
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </template>
            </v-radio> -->
          </v-radio-group>
  
        </v-card>
        <v-btn
          :disabled="!datosUsuario.tipo_envio"
          rounded
          class="mt-5"
          color="blue"
          dark
          @click="e6 = 4"
        >
          Continuar
        </v-btn>
        <v-btn class="mt-5" color="red" text @click="cerrarVentana"> Cancelar </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4">
        Metodo de Pago:
        <strong style="color: blue">{{ verSeleccionPago }}</strong>
        <p class="text-caption">
          (al final se te generara tu comprobante NO olvides Guardarlo.)
        </p>
      </v-stepper-step>
      <v-stepper-content class="mt-n11" step="4">
        <v-row no-gutters>
          <v-col>
            <v-card
              @click="pagoFisico"
              min-width="170px"
              @mouseenter="isHovered = true"
              @mouseleave="isHovered = false"
              :class="{ grow: isHovered }"
              rounded="xl"
              color="#dbecfc"
              class="mb-12 ml-8 mr-8 mt-5"
              
            >
              <p class="text-overline mx-5 text-center">Pago Contra Entrega</p>
              <v-card-text>
                Deberas Recojer en la tienda Mera Atlacomulco Ubicada en:
                <a href="https://goo.gl/maps/fEtFTvZNemBN5F6i6" target="_blank"
                  >Ingeniería Mera, Avenida Juan N. Resendiz MZ 038,
                  Atlacomulco, Estado de México</a
                >
                No olvides llevar tu comprobante!.
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col>
            <v-card
              @click="pagoOnline"
              @mouseenter="isHovered = true"
              @mouseleave="isHovered = false"
              :class="{ grow: isHovered }"
              id="credit-card"
              rounded="xl"
              class="mb-12 ml-8 mr-8 mt-5"
              height="200px"
            >
              <v-card-title>
                <v-layout justify-space-between>
                  <v-avatar tile class="rounded">
                    <v-img src="@/assets/images/chip.png"> </v-img>
                  </v-avatar>
                  <v-avatar tile>
                    <v-img src="@/assets/images/visa.png"> </v-img>
                  </v-avatar>
                </v-layout>
                <v-card-text class="white--text text-overline">
                  1233 &nbsp;1233 &nbsp;1233 &nbsp;1233 &nbsp;
                </v-card-text>
                <v-layout justify-space-between>
                  <v-card-text class="mt-n4 white--text text-overline">
                    Propietario: <br />
                    Jonh Doe
                  </v-card-text>
                  <v-card-text class="mt-n4 white--text text-overline">
                    Expiracion: <br />
                    04/28
                  </v-card-text>
                </v-layout>
              </v-card-title>
            </v-card>
          </v-col> -->
        </v-row>
        <v-btn
          :disabled="!datosUsuario.seleccionPago"
          @click="confirmarCompra"
          class="mb-2 mt-n4"
          rounded
          dark
          color="blue"
        >
          Continuar
        </v-btn>
        <v-btn @click="cerrarVentana" class="mb-2 mt-n4" color="red" text>
          Cancelar
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>


  <script>
export default {
  props: ["datosCarro", "ventana"],
  watch: {
    ventana(valor) {
      this.metodoPago = valor;
    },metodoPago(value) {
      if (value) {
        // El diálogo se abrió
        this.obtenerCarrito();
      } else {
        // El diálogo se cerró
      }
    }
  },
  data() {
    return {
      metodoPago: false,
      e6: 1,
      isHovered: false,
      datosUsuario: [],
      verSeleccionPago: "",
      municipios: [{ nombre: "Ixtlahuaca" }, { nombre: "Atlacomulco" }],
      carrito: [],
    };
  },
  computed: {
   
  },
  methods: {
    cerrarVentana() {
      this.$emit("closeAddWindow");
      this.verSeleccionPago = "";
      this.e6 = 1;
    },
    confirmarCompra() {
      //  const peticion = this.enviarPeticionAPI(`${this.$store.state.apiURL}confirmarCompra`,{},'POST');
      //  peticion.then((datos) => {
      //           this.datosUsuario = datos.datos;
      //       });
     
      const dataTotal = [];
      this.carrito.forEach((element) => {
        dataTotal.push({ subtotal: element.precio * element.cantidad });
      });
      const sumall = dataTotal
        .map((item) => item.subtotal)
        .reduce((prev, curr) => prev + curr, 0);

        this.datosUsuario.totalCompra = sumall;


      this.obtenerCarrito();
      const arrayCompra = [];
      const objetcCompra = [];
      for (let i = 0; i < this.carrito.length; i++) {
        const element = this.carrito[i];
        
        arrayCompra[i] = [
          element.nombre,
          "$ " + parseFloat(element.precio),
          element.cantidad,
          "$ " + element.precio * element.cantidad,
        ];
          objetcCompra.push({
            usuario_id: this.datosUsuario.id,
            producto_id: element.producto_id,
            cantidad: element.cantidad,
            subtotal: element.cantidad * element.precio,
            metodo_pago: this.datosUsuario.seleccionPago
          })

      }


      arrayCompra.unshift(["Productos", "Precio C/u", "Cantidad", "Sub Total"]);

      const datosUsuarioCompra = {
        arrayData: arrayCompra,
        objectData: this.datosUsuario,
        saveBdObject: objetcCompra
      };
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}GenerarTicketCompraTienda`,
        datosUsuarioCompra,
        "POST"
      );
      peticion.then((datos) => {
        datos;
        this.$router.push('/users/pedidosActivos')
        this.e6  = 1;
      });

    },
    pagoFisico() {
      this.datosUsuario.seleccionPago = "Pendiente";
      this.verSeleccionPago = this.datosUsuario.seleccionPago;

      this.obtenerCarrito();
    },
    pagoOnline() {
      this.datosUsuario.seleccionPago = "Pagado";
      this.verSeleccionPago = this.datosUsuario.seleccionPago;
      this.obtenerCarrito();
    },

    obtenerCarrito() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.carrito = datos.datos;
      });
    },

    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
  },
  created() {
    this.obtenerAcceso();
    this.obtenerCarrito();
  },
  mounted() {
    this.obtenerCarrito();
  },
};
</script>

<style
 scoped>
#credit-card {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}
.grow:hover {
  transform: scale(1.05);
}
</style>