<template>
          <v-row>
            <v-col>  
              <v-card   width="400px" rounded="xl" class=" mx-auto text-center">
                <v-card-actions class="justify-end">
                    <v-btn fab small
                    @click="abrirEditar"
                    > <v-icon color="blue">mdi-account-edit-outline</v-icon> </v-btn>
                </v-card-actions>
              <v-avatar  class="mt-n10" size="200" >
                              
                <v-img  :src="url+datosUsuario.foto_perfil "/>

                <div class="hover-button">
                  <v-btn @click="editarFoto = true" rounded class="text-caption">
                    Editar Foto  &nbsp;
                    <v-icon>mdi-image</v-icon>
                  </v-btn>
                </div>
              </v-avatar>
                  <br> <br>
                <v-row>
                  <v-col> 0 <br>  <h5>Proyectos</h5></v-col>
                  <v-col> 13 <br> <h5>Pedidos</h5></v-col>
                  <v-col> 14 <br> <h5>Compras</h5></v-col>
                </v-row>
                <br>
                  <h3>{{datosUsuario.nombre+' '+datosUsuario.apellidoP +' '+datosUsuario.apellidoM}}</h3>
                  <h5>{{datosUsuario.pais+' '+datosUsuario.estado}}</h5>
                  <p>{{ datosUsuario.telefono }} - {{ datosUsuario.correo_electronico }} </p>
                    <v-divider/>
                      <p>
                   {{ datosUsuario.direccion_referencia }}
                   
                      </p>
            </v-card>
          </v-col>
           
           
          <v-dialog persistent width="auto" v-model="editarFoto">
                <v-card max-width="500px" class="mx-auto" rounded="xl">
                 
                    
                    <v-card-actions>
                      <v-card-title>Elige Una foto de tus archivos</v-card-title>
                      <v-btn @click="editarFoto = false" fab x-small color="red" dark> <v-icon>mdi-close</v-icon> </v-btn>
                      <v-btn 
                      :disabled="!selectedFile"
                      @click="cambiarFoto" fab x-small color="blue"  dark> <v-icon>mdi-check-bold</v-icon> </v-btn>
                    </v-card-actions>
                  

                    <v-card-text class="text-center">
                        <v-avatar size="230">
                          <img
              
                          v-if="imagePreviewURL"
                          :src="imagePreviewURL"
                          style="
                            width: 100%;
                            border-top-left-radius: 22px !important;
                            border-bottom-left-radius: 22px !important;
                          "
                        />
                          <v-img v-else :src="url+datosUsuario.foto_perfil"></v-img>
                        </v-avatar>
                        <v-file-input
                        class="mt-10"
                    outlined
                    rounded
                    filled
                    dense
                    v-model="selectedFile"
                    label="Selecciona una Imagen"
                    required
                    @change="onFileChange"
                  ></v-file-input>
                    </v-card-text>

                </v-card>

          </v-dialog> 

            <v-dialog persistent width="600px" v-model="editarPerfil">
              <editar-perfil @actualizarCarta="obtenerAcceso" @cerrarVentana="editarPerfil = false"></editar-perfil>

            </v-dialog>


          </v-row>
 
  </template>
    
    <script>
    import axios from 'axios';
    import EditarPerfil from '../components/EditarPerfil.vue';
  import moment from 'moment'
  export default {
    components: {  EditarPerfil },
    data: () => ({
     
      selectedFile: null,
      imagePreviewURL: null,
      editarFoto:false,
      editarPerfil:false,
      enviarDatos: [],
      menu: false,
      arrayEvents: [],
      datosFecha: '',
      datosUsuario: [],
      productos:[],
      url:'',
      comprobantes:[]
    }),
    computed:{  
     
      sumall() {
        const data = [];
        this.productos.forEach((element) => {
          data.push({ subtotal: element.precio * element.producto_cantidad });
        });
        const sumall = data
          .map((item) => item.subtotal)
          .reduce((prev, curr) => prev + curr, 0);
  
        return sumall;
      },  
    },
    mounted() {
     
     
    },
    methods: {
      onFileChange(payload) {
        //const file = payload.target.files[0];
        const file = payload;
        const objeto = { datos: 1 };
        const prueba = { file, objeto };
        console.log(prueba);
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
        } else {
          this.imagePreviewURL = null;
        }
      },
   
        cambiarFoto(){
         
          const formData = new FormData();
          formData.append("image", this.selectedFile);
        formData.append("id", this.datosUsuario.id);
        //enviar la imagen al servidor con axios
        axios.post( `${this.$store.state.apiURL}actualizarFotoPerfil`, formData)
          .then((response) => {
            response;
            this.mostrarMensajeConfirmacion("IMAGEN ACTUALIZADA");
            
            this.editarFoto = false;
              this.obtenerAcceso();
          })
          .catch((error) => {
            console.log(error);
          });
        },
  
        abrirEditar(){
          this.enviarDatos = this.datosUsuario;
        this.editarPerfil = true;
        },
        cerrarEditar(){
          this.editarPerfil = false;
          this.obtenerAcceso();
        },
  
      inicio() {
        this.$router.push("/");
      },
      cerrarSesion() {
        window.localStorage.clear();
        this.$router.push("/");
      },
      obtenerAcceso() {
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}accesoUsuarioTienda`
        );
        peticion.then((datos) => {
          this.datosUsuario = datos.datos;
        });
      },
      verProductos() {
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}ListarPedidosUsuarios`,
        );
        peticion.then((datos) => {
          //this.mostrarMensajeConfirmacion(mensaje);
          this.productos = datos.datos;
          this.productos.forEach(element => {
            this.arrayEvents.push(element.fecha)
     });
  
        });
      },
      verProductosFecha(item) {
        this.$refs.menu.save(item);
  
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,{
            fecha: item
          },'POST'
        );
        peticion.then((datos) => {
          //this.mostrarMensajeConfirmacion(mensaje);
          this.productos = datos.datos;
        });
      },
      obtenerComprobantes(){
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}listarComprobanteClientes`
        )
        peticion.then(({datos}) => {
            this.comprobantes = datos;
        })
      },
  
    verProductosFechaMounted() {
       
  
       const date = moment().format('YYYY-MM-DD');
        const peticion = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,{
            fecha: date
          },'POST'
        );
        peticion.then((datos) => {
          //this.mostrarMensajeConfirmacion(mensaje);
          this.productos = datos.datos;
        });
      },
    },
  
  
    created() {
      this.obtenerAcceso();
      this.verProductos();
  
      this.url = `${this.$store.state.imgURL}`;
       this.obtenerComprobantes();
  
       this.verProductosFechaMounted();
    },
  };
  </script>
    <style scoped>
  
    .fondo-body{
      width: 100%;
      height: 50vh;
      background-color: #85FFBD;
      background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
      
  
  
    }
    .hover-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      justify-content: center;
      align-items: center;
    }
    
    .v-avatar:hover .hover-button {
      display: flex;
    }
    
    
  </style>
    