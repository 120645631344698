<template>

    <v-card dark max-width="800px" class="color-card mx-auto rounded-xl">
      <v-card-title>Presenta este codigo en la Tienda
        <v-spacer>

        </v-spacer>
          <v-btn
          @click="$emit('cerrarQrCode')"
          fab color="red" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text>
        Presenta este codigo en nuestra Tienda para recojer tus Productos.
      </v-card-text>
      <v-card-text class="text-center">
        <v-avatar size="300"  tile>
         <v-card rounded="xl" elevation="4">
          <div id="codigo-qr"></div>
         </v-card>
        </v-avatar>
        <h2>{{datosUsuario.nombre+' '+datosUsuario.apellidoP+' '+datosUsuario.apellidoM}}

            <br> <br>
            {{ datosUsuario.fecha_entrega }}
        </h2>
      </v-card-text>
    </v-card>
  
</template>

<script>
import moment from "moment";
import qrcode from "qrcode";
export default {
  //Funcion
  data: () => ({
    datosUsuario: [],
    id: 0,
  }),
  computed:{
      fechaHoy(){
        return moment().format('DD-MM-YYYY')
      }
  },
  //Metodos methods,created,computed,before,after,mounted, ETC
  methods: {
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;

        const peticion2 = this.enviarPeticionAPI(
          `${this.$store.state.apiURL}generarQrCode`,
          this.datosUsuario,
          "POST"
        );
        peticion2.then((datos) => {
            
          const codigo = datos.datos[0].no_pedido;
          const texto = codigo;

          // Opciones de configuración
          const opciones = {
            errorCorrectionLevel: "H",
            type: "image/png",
            quality: 0.92,
            margin: 1,
            width: 300,
            height: 300,
          };

          // Generar el código QR como una URL de datos
          qrcode.toDataURL(texto, opciones, function (error, url) {
            if (error) {
              console.error(error);
            } else {
              const divCodigoQR = document.getElementById("codigo-qr");
              const imagenCodigoQR = document.createElement("img");
              imagenCodigoQR.src = url;
              divCodigoQR.appendChild(imagenCodigoQR);
             
            }
          });
        });
      });
    },
  },
  created() {
    this.obtenerAcceso();
  },
};
</script>


<style scoped>
.color-card{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #222a4d 0%, #0e1325 100%)
}
</style>  