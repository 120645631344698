  <template>
    <v-container rounded="xl" class="mx-auto" elevation="8">
      <v-slide-group dark class="pa-4" active-class="success" show-arrows>
        <v-slide-item v-for="(productos, index) in packs" :key="index">
          <v-card
          
            rounded="xl"
            class=" card-color text-left ma-4 text-container-card"
            height="auto"
            width="250"
          >
            <v-img height="125" :src="url + productos.imagen"></v-img>
            <v-toolbar color="transparent" class="mt-n7" flat>
              <v-avatar tile size="90">
                <v-img src="@/assets/images/meraLogo.png"></v-img>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-avatar size="auto" class="mr-2 rounded-xl" tile>
                <div class="five">
                  <span class="white--text text-h6 text-center"
                    >$ {{ productos.precio }}</span
                  >
                </div>
              </v-avatar>
            </v-toolbar>
            <v-card-text>
              <span class="white--text text-left">{{ productos.categoria }}</span>
              <h2 class="blue--text text-container-card">
                {{ productos.nombre }}
              </h2>
              <h3 class="text-left green--text">
                $ {{ productos.precio }} <span>C/u</span>
              </h3>
              <h4 class="text-left blue--text">
                {{ productos.stock }} Disponibles
              </h4>

              <v-card-actions class="justify-end">
                <v-btn
                  :disabled="
                    productos.stock <= 0 || datosUsuario === 'No Loggeado'
                  "
                  @click="addCarrito(productos)"
                  class="blue"
                  fab
                  dark
                  small
                  color="blue"
                >
                  <v-icon dark> mdi-cart </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-container>
</template>

  <script>
export default {
  data: () => ({
    url: "",
    packs: [],
    datosUsuario: [],
  }),
  methods: {
    addCarrito(item) {
     
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}crearCarrito`,
        {
          producto_id: item.id,
          cantidad: 1,
        },
        "POST"
      );
      peticion.then(({ datos }) => {
        datos
        this.mostrarMensajeConfirmacion("Producto Agregadoo al Carrito");
        this.$emit("actualizarCantidad");
      });
    },
    masVendidos() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}topProductosVendidos`
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.packs = datos.datos;
       
      });
    },
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      
      });
    },
  },
  created() {
    this.obtenerAcceso();
    this.masVendidos();
    this.url = `${this.$store.state.imgURL}`;
   
  },
};
</script>

  <style scoped>
.frist-card {
  min-width: 373px;
  border-radius: 20px;
  height: 475px;
  background-image: linear-gradient( 86.3deg,  rgba(0,119,182,1) 3.6%, rgba(8,24,68,1) 87.6% );

}
.secondary-card {
  min-width: 257px;
  border-radius: 20px;
  height: 225px;
  background-image: linear-gradient( 86.3deg,  rgba(0,119,182,1) 3.6%, rgba(8,24,68,1) 87.6% );

}
.five {
  width: 100%;
  height: 50%;
  background: #1f8323;
}
</style>