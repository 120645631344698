<template>
  
      
         <v-card class="color-card" dark rounded="xl">
             <v-card-title class="justify-space-between">
               <span class="text-h5">Perfil de Usuario</span>
 
               <v-card-actions >
                
                 <v-btn
               
                 @click="actualizarDatos"
                 fab
                 small
                   color="blue"
                 dark
                
                 >
                   <v-icon>
                     mdi-checkbox-marked-circle-outline
                   </v-icon>
                 </v-btn>

                 <v-btn
               
                 @click="$emit('cerrarVentana')"
                 fab
                 small
                   color="red"
                 dark
                 >
                   <v-icon>
                     mdi-close-circle-multiple-outline
                   </v-icon>
                 </v-btn>
                
                
               </v-card-actions>
             </v-card-title>
             <v-card-subtitle>
                 <p class="text-caption">Esta informacion se usara para tus compras <br>
                     asi que asegurate de llenar estos campos correctamente</p>
             </v-card-subtitle>
             <v-card-text>
               <v-container>
                 <v-row>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     readonly
                     v-model="datosUsuario.nombre"
                     rounded
                     dense 
                     outlined
                       label="Primer Nombre*"
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     readonly
                     v-model="datosUsuario.apellidoP"
                     rounded
                     dense 
                     outlined
                       label="Apellido Paterno*"
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     readonly
                     v-model="datosUsuario.apellidoM"
                     rounded
                     dense 
                     outlined
                       label="Apellido Materno*"
                       persistent-hint
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     readonly
                     v-model="datosUsuario.pais"
                     rounded
                     dense 
                     outlined
                       label="Pais*"
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     color="green"
                     v-model="datosUsuario.estado"
                     rounded
                     dense 
                     outlined
                       label="Estado*"
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     color="green"
                     v-model="datosUsuario.municipio"
                     rounded
                     dense 
                     outlined
                       label="Municipio*"
                       persistent-hint
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                     md="4"
                   >
                     <v-text-field 
                     color="green"
                     v-model="datosUsuario.codigo_postal"
                     rounded
                     dense 
                     outlined
                       label="Codigo Postal*"
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                    cols="8"
                    sm="6"
                    md="8"
                   >
                     <v-text-field 
                     color="green"
                     v-model="datosUsuario.direccion_referencia"
                     rounded
                     dense 
                     outlined
                       label="Direccion de Referencia (Barrio,calle,colonia,manzana)*"
                     ></v-text-field>
                   </v-col>
                   
                   <v-col
                     cols="12"
                     sm="6"
                   >
                     <v-text-field 
                     color="green"
                     v-model="datosUsuario.telefono"
                     number
                     rounded
                     dense 
                     outlined
                     label="Telefono*"
                       required
                     ></v-text-field>
                   </v-col>
                   <v-col
                     cols="12"
                     sm="6"
                   >
                     <v-text-field 
                     readonly
                     v-model="datosUsuario.fechaNacimiento"
                     rounded
                     dense 
                     outlined 
                       label="Fecha Nacimiento*"
                       multiple
                     ></v-text-field>
                   </v-col>
                  
                 </v-row>
               </v-container>
               <small>Todos los campos son obligatorios</small>
             </v-card-text>
             
           </v-card>
    
   
 </template>
 
 <script>

   export default {
     data () {
       return {
         editarPerfil: false,
         datosUsuario: []
       }
     },
     props: ['datos','ventana'],
   watch: {
     datos(valor) {
       this.datosUsuario = { ...valor };
    
     },
     ventana(valor){
       
         this.editarPerfil = valor
     }
   },
     methods:{
        actualizarDatos(){
            parseInt(this.datosUsuario.codigo_postal)
            parseInt(this.datosUsuario.telefono)
            const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}actualizarUsuariosTienda/${this.datosUsuario.id}`,this.datosUsuario,'PUT'
      );
      peticion.then((datos) => {
        this.mostrarMensajeConfirmacion('Usuario Actualizado')
        this.obtenerAcceso()
        this.$emit('actualizarCarta')
        
        datos
      });
        },
        obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
         
     },
     created(){
      this.obtenerAcceso()
      
     }
   
   }
 </script>

 <style scoped>
 .color-card {
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #222a4d 0%, #0e1325 100%);
}
</style>