
<template>
  <div class="fondo-main mb-5">

 <div v-if="datosUsuario.estado === 'Define tu estado' 
 || datosUsuario.municipio === 'Define Tu Municipio'
 || datosUsuario.codigo_postal === 22222
 || datosUsuario.direccion_referencia ==='Define tu Direccion'
 || datosUsuario.telefono === 7121000000
 ">
      <v-dialog
      class="mx-auto"
        v-model="dialog"
        persistent
        transition="dialog-top-transition"
        max-width="400"
      >
        <v-card dark color="#21294B"  rounded="xl">
          <v-toolbar class="color-card" dark
            >Bienvenido {{datosUsuario.nombre+' '+datosUsuario.apellidoP+' '+datosUsuario.apellidoM}}</v-toolbar
          >
          <v-card-text>
            <div class="text-h2 mt-10">Antes de Continuar!</div>
            <div class="text-h7 ">
             <h3>Te pedimos que edites tu informacion personal</h3>
             <br>
             <h4 >
              Tu información personal es un conjunto de datos que te
                identifica y describe de manera única. 
                
                <h3 class="blue--text">
                  (Estado,Municipio,Codigo Postal,Direccion de Referencia,Telefono)
                </h3>
                Estos elementos son cruciales en la era
                digital, ya que te permiten comunicarte y conectarte con otras
                personas de diversas formas.
             </h4>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="blue" text 

            @click="abrirEditar"
              >Editar
              <v-icon>mdi-badge-account-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>



    <v-app-bar height="70px" class="transparent">
      <v-avatar tile size="60">
        <v-img src="@/assets/images/meraLogo.png"></v-img>
      </v-avatar>

      <v-btn
          color="blue"
        class="mx-2"
        dark
        v-for="(buttons, index) in items"
        :key="index"
        @click="botonName(buttons.title)"
        text
        rounded
        :to="buttons.to"
      >
      <v-icon>{{buttons.icon}} </v-icon>
      &nbsp; {{ buttons.title }}
      </v-btn>
      <v-spacer />
    </v-app-bar>
    <div>

      <v-card class="rounded-xl mt-10 mx-5">
        <v-parallax
          style="height: 300px"
          :src="$store.state.imgURL+'corteLaser.jpeg'"
        />
      </v-card>
      <v-card  elevation="0" class="d-none d-md-block transparent mx-16 mt-n16">
        <v-row>
          <v-col cols="2">
            <v-avatar
              style="border: 5px solid white; background-color: white"
              class="mt-n10"
              size="200"
            >
              <v-img :src="url + datosUsuario.foto_perfil" />

              <div class="hover-button">
                <v-btn @click="editarFoto = true" rounded class="text-caption">
                  Editar Foto &nbsp;
                  <v-icon>mdi-image</v-icon>
                </v-btn>
              </div>
            </v-avatar></v-col
          >

          <v-col cols="10">
             <!-- FOTO DE PERFIL -->
            <v-card-text class="

            white--text mt-12">

              <v-row>
                <v-col cols="9">
                  <h1>
                    {{ datosUsuario.nombre }} {{ datosUsuario.apellidoP }}
                    {{ datosUsuario.apellidoM }}
                  </h1>
                  <h3>
                    <v-icon dark>mdi-message-reply-text-outline</v-icon>
                    Intereses: #TecnologíaAvanzada, #Electrónica, #Gadgets,
                    #InnovaciónTecnológica, #Mecatrónica, #Robótica,
                    #Automatización,
                  </h3>
                  <h3>
                    <v-icon dark>mdi-map-marker-radius-outline</v-icon>
                    Direccion: {{ datosUsuario.estado }},
                    {{ datosUsuario.municipio }}, #{{
                      datosUsuario.codigo_postal
                    }}
                  </h3>
                  <h3>
                    <v-icon dark>mdi-store-settings-outline</v-icon> Referencia:
                    {{ datosUsuario.direccion_referencia }}
                  </h3>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-row>
                    <v-col>
                      <v-btn
                      @click="cerrarSesion"
                      class="rounded-lg" text outlined dark>
                        Log out
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="editarPerfil = true"
                        color="#5c6798"
                        class="rounded-lg"
                        dark
                      >
                        Editar Perfil
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <v-card-text class="d-sm-block d-md-none">
        <v-row>
          <v-col>

            <v-avatar
            style="border: 5px solid white; background-color: white"
            class="mt-n16"
            size="200"
          >
            <v-img :src="url + datosUsuario.foto_perfil" />

            <div class="hover-button">
              <v-btn @click="editarFoto = true" rounded class="text-caption">
                Editar Foto &nbsp;
                <v-icon>mdi-image</v-icon>
              </v-btn>
            </div>
          </v-avatar>

          </v-col>
          <v-col class="white--text">
            <h1>{{datosUsuario.nombre}}</h1>
            <h1>{{datosUsuario.apellidoP}}</h1>

            <v-row>
              <v-col>
                <v-btn
                small
                @click="cerrarSesion"
                class="rounded-lg" text outlined dark>
                  Log out
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  small
                  @click="editarPerfil = true"
                  color="#5c6798"
                  class="rounded-lg"
                  dark
                >
                  Editar Perfil
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
         <!-- ACTIVIDADES -->
      <v-card rounded="xl" class="card-color mx-10">
        <v-card-text class="white--text">
          <h2>Descripcion General Actividades</h2>
          <br />
          <h3><v-icon dark>mdi-eye</v-icon> Visible solo por ti</h3>
        </v-card-text>
        <v-divider dark></v-divider>
        <v-card-text class="d-none d-md-block  white--text">
          <v-row class=" ">
            <v-col>
              <v-row>
                <v-col cols="2"
                  ><h1><v-icon dark size="60">mdi-qrcode</v-icon></h1></v-col
                >
                <v-col>
                  <h1 class="blue--text">Recoje tus Productos!</h1>
                  <br />
                   <span v-if="productosPendientes.length >=1">
                    Genera tu codigo QR con el boton amariillo de la esquina inferior derecha Para recojer tus productos! .

                   </span>
                   <span class="text-right" v-else>
                    No tienes productos pendientes por recojer ve a comprar algunos! .
                    <v-btn
                    to="categorias/Modulos"
                    outlined dark text fab x-small>
                        <v-icon dark>mdi-keyboard-return</v-icon>
                    </v-btn>
                    Ir...

                   </span>

                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row >
                <v-col cols="2"
                  ><h1><v-icon dark size="60">mdi-package-variant</v-icon></h1></v-col
                >
                <v-col>
                  <h1 class="blue--text">{{ allProductos.length }} Productos Registrados</h1>
                  <br />
                  Esta es la cantidad de productos totales registrados en tu cuenta.
                </v-col>

              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="2"
                  ><h1><v-icon dark size="60">mdi-currency-usd</v-icon></h1></v-col
                >
                <v-col>
                  <h1 class="blue--text">{{ sumallTotal }} Pesos mxn</h1>
                  <br />
                  Este es total de todas tus compras realizadas hasta el dia de hoy
                </v-col>
              </v-row>
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-text class=" white--text px-6 d-sm-block d-md-none">
          <v-row>

           <h1 class="blue--text">Recoje tus Productos!</h1>
               <br />
                <span v-if="productosPendientes.length >=1">
                 Genera tu codigo QR con el boton amariillo de la esquina inferior derecha Para recojer tus productos! .

                </span>
                <span class="text-right" v-else>
                 No tienes productos pendientes por recojer ve a comprar algunos! .
                 <v-btn
                 to="categorias/Modulos"
                 outlined dark text fab x-small>
                     <v-icon dark>mdi-keyboard-return</v-icon>
                 </v-btn>
                 Ir...

                </span>


          </v-row>
          <v-row>


           <h1 class="blue--text mt-5 ">{{ sumallTotal }} Pesos mxn</h1>
           <br />
           Este es total de todas tus compras realizadas hasta el dia de hoy


          </v-row>

       </v-card-text>
      </v-card>

      <v-card rounded="xl" color="transparent" elevation="0" dark class="mt-5 d-none d-md-block  mx-10">

        <v-row class=" mx-10 mt-10 mb-16">
          <!-- LADO IZQUIERDO -->
        <v-col cols="4">
          <v-row>
            <v-card width="100%" class="rounded-lg card-color">
              <v-card-title class="card-color">
                <v-avatar tile color="#2f3861" class="rounded-lg">
                  <v-icon dark> mdi-currency-usd </v-icon>
                </v-avatar>
                <h4 class="ml-5 white--text">
                  Pago Total
                  <br />
                  <h5>Productos</h5>
                </h4>
                <v-spacer />
                <h2 class="white--text">$ {{sumall}}</h2>
              </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col>

                        <h2 class="white--text">Escoje una fecha en la que pasas a recogerlos!</h2>
                        <br>

                        
                        <v-menu

                        rounded="xl"
                        ref="menuEntrega"
                        v-model="menuEntrega"
                        :close-on-content-click="false"
                        :return-value.sync="fecha_entrega"
        
        
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                           
                            <v-btn
                            :disabled="sumall == 0"
                              rounded
                              outlined
                              color="green"
                            v-bind="attrs"
                            v-on="on"
                            text dark>
                             Seleccionar <v-icon  dark>mdi-calendar</v-icon>
                            </v-btn>
                         
                         
        
                        </template>
                        <v-date-picker
                          
                          event-color="green lighten-1"
                          v-model="fecha_entrega"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancelar
                          </v-btn>
                          <v-btn
                            :disabled="!fecha_entrega"
                            text
                            color="pink"
                            @click="seleccionarFechaEntrega(fecha_entrega)"
                          >
                            Confirmar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                      &nbsp;

                    

                    </v-col>
                    <v-col class="text-right">
                      <span v-if="!fecha_entrega" class="white--text "><v-chip color="blue">
                        <h2> {{ datosUsuario.fecha_entrega }}</h2>
                       </v-chip></span>
                       <span v-else class="white--text text-button"><v-chip color="green">
                         <h2>{{fecha_entrega}}</h2>
                       </v-chip></span>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-card>
          </v-row>
          <v-row class="mt-10">
            <v-card class="rounded-lg card-color">
              <v-card-title>
                <v-avatar tile color="#2f3861" class="rounded-lg">
                  <v-icon dark> mdi-cart </v-icon>
                </v-avatar>
                <h4 class="ml-5 white--text">
                  Informacion: <br />
                  <h5>Informacion Personal</h5>
                </h4>
              </v-card-title>
              <v-card-text class="white--text text-h8">
                Tu información personal es un conjunto de datos que te
                identifica y describe de manera única. Incluye detalles como tu
                número de teléfono, dirección de correo electrónico y otros
                datos de contacto. Estos elementos son cruciales en la era
                digital, ya que te permiten comunicarte y conectarte con otras
                personas de diversas formas.
                <br />
                <h3>un Gusto!</h3>
              </v-card-text>
              <v-card-text class="mt-n3">
                <v-row>
                  <v-col cols="2"
                    ><v-icon size="40" dark
                      >mdi-cellphone-message</v-icon
                    ></v-col
                  >
                  <v-col
                    ><h2 class="white--text">
                      Telefono <br />
                      <h6>{{datosUsuario.telefono}}</h6>
                    </h2></v-col
                  ></v-row
                >
              </v-card-text>
              <v-card-text class="mt-n3">
                <v-row>
                  <v-col cols="2"
                    ><v-icon size="40" dark
                      >mdi-email-check-outline</v-icon
                    ></v-col
                  >
                  <v-col
                    ><h2 class="white--text">
                      Correo <br />
                      <h6>{{datosUsuario.correo_electronico}}</h6>
                    </h2></v-col
                  ></v-row
                >
              </v-card-text>
              <v-card-text class="mt-n3">
                <v-row>
                  <v-col cols="2"
                    ><v-icon size="40" dark
                      >mdi-calendar-multiselect-outline</v-icon
                    ></v-col
                  >
                  <v-col
                    ><h2 class="white--text">
                      Cumpleaños <br />
                      <h6>{{datosUsuario.fechaNacimiento}}</h6>
                    </h2></v-col
                  ></v-row
                >
              </v-card-text>
              <v-card-text class="mt-n3">
                <v-row>
                  <v-col cols="2"
                    ><v-icon size="40" dark
                      >mdi-map-marker-radius-outline</v-icon
                    ></v-col
                  >
                  <v-col
                    ><h2 class="white--text">
                      Codigo Postal <br />
                      <h6>#{{datosUsuario.codigo_postal}}</h6>
                    </h2></v-col
                  ></v-row
                >
              </v-card-text>
            </v-card>
          </v-row>

        </v-col>
          <!-- LADO DERECHO -->
        <v-col style="margin-left: 120px;"  cols="6">
          <v-row>
            <v-card width="100%" class="card-color rounded-lg">
              <v-card-title>
                <v-avatar tile color="#2f3861" class="rounded-lg">
                  <v-icon dark> mdi-cart </v-icon>
                </v-avatar>
                <h4 class="ml-5 white--text">
                  Productos Encargados <br />
                  <h5 v-if="productosPendientes.length >=1">Estos son tus productos pendientes por recojer</h5>
                  <h5 v-else>Aun no tienes productos pendientes por recojer :( </h5>
                </h4>
              </v-card-title>
              <v-card-text>
                <v-row v-for="(producto, i) in productosPendientes" :key="i">
                  <v-col cols="1">
                    <v-avatar size="60">
                      <v-img :src="url+producto.imagen"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col class="white--text ml-3">
                   <v-row>
                    <v-col> <h2 class="blue--text">{{producto.nombre}}</h2>
                      <h4>Encargado el: {{producto.fecha}}</h4>
                      <h3>
                        Precio:  <span class="green--text">{{producto.precio}}</span>
                        Cantidad: {{producto.producto_cantidad}}
                        Subtotal: <span class="green--text">{{producto.sub_total}}</span>
                      </h3></v-col>

                      <v-col class="text-center mt-5">
                        <h1 class="blue--text">Estado Pago: <br>
                            <h4 class="orange--text">{{ producto.metodo_pago }}</h4>
                        </h1>
                      </v-col>
                   </v-row>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>

          <!-- HISTORIAL -->
          <v-row class="mt-10">
            <v-card width="100%" class="card-color rounded-lg">
              <v-card-title>
                <v-menu

                rounded="xl"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="datosFecha"


                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar color="#2f3861" class=" rounded-lg" v-bind="attrs"
                    v-on="on">
                    <v-btn  text dark>
                      <v-icon  dark>mdi-calendar</v-icon>
                    </v-btn>
                 </v-avatar>

                </template>
                <v-date-picker
                  :events="arrayEvents"
                  event-color="green lighten-1"
                  v-model="datosFecha"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!datosFecha"
                    text
                    color="primary"
                    @click="verProductosFecha(datosFecha)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
                <h4 class="ml-5 white--text">
                  Historial <br />
                  <h5>Historial de Compras</h5>
                </h4>
                <v-spacer />

              </v-card-title>
              <v-card-text v-if="productos.length >=1">
                <v-row v-for="(producto,i) in productos" :key="i">
                  <v-col cols="1">
                    <v-avatar size="60">
                      <v-img :src="url+producto.imagen"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col class="white--text ml-3">
                    <v-row>
                     <v-col > <h2 class="blue--text">{{producto.nombre}}</h2>
                       <h4>Encargado el: {{producto.fecha}}</h4>
                       <h3>
                        Precio:  <span class="green--text">{{producto.precio}}</span>
                        Cantidad: {{producto.producto_cantidad}}
                        Subtotal: <span class="green--text">{{producto.sub_total}}</span>
                      </h3></v-col>

                       <v-col class="text-center mt-5">
                         <h1 class="blue--text">Estado Pago: <br>
                             <span class="green--text">{{ producto.metodo_pago }}</span>
                         </h1>
                       </v-col>
                    </v-row>

                   </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else class="text-center white--text">
                <h2>Selecciona una fecha en el icono del calendario para ver tu historial</h2>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      </v-card>
        <!-- // CARTAS DE ACTIVIDADES Y PRODUCTOS -->


      <v-card rounded="xl" color="transparent" elevation="0" dark class="mt-5 d-sm-block d-md-none  mx-10">

        <v-card width="100%" class="rounded-lg card-color">
          <v-card-title class="card-color">
            <v-avatar tile color="#2f3861" class="rounded-lg">
              <v-icon dark> mdi-currency-usd </v-icon>
            </v-avatar>
            <h4 class="ml-5 white--text">
              Pago Total
              <br />
              <h5>Productos</h5>
            </h4>
            <v-spacer />
            <h2 class="white--text">$ {{sumall}}</h2>
          </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                    <h2 class="white--text">Escoje una fecha en la que pasas a recogerlos!</h2>
                    <br>

                    
                    <v-menu

                    rounded="xl"
                    ref="menuEntregaMobile"
                    v-model="menuEntregaMobile"
                    :close-on-content-click="false"
                    :return-value.sync="fecha_entrega"
    
    
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                       
                        <v-btn
                          rounded
                          outlined
                          color="green"
                        v-bind="attrs"
                        v-on="on"
                        text dark>
                         Seleccionar <v-icon  dark>mdi-calendar</v-icon>
                        </v-btn>
                     
                     
    
                    </template>
                    <v-date-picker
                      
                      event-color="green lighten-1"
                      v-model="fecha_entrega"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEntregaMobile = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        :disabled="!fecha_entrega"
                        text
                        color="pink"
                        @click="seleccionarFechaEntrega(fecha_entrega)"
                      >
                        Confirmar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  &nbsp;

                

                </v-col>
                <v-col class="text-right">
                  <span v-if="!fecha_entrega" class="white--text "><v-chip color="blue">
                    <h2> {{ datosUsuario.fecha_entrega }}</h2>
                   </v-chip></span>
                   <span v-else class="white--text text-button"><v-chip color="green">
                     <h2>{{fecha_entrega}}</h2>
                   </v-chip></span>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>

        <v-card width="100%" class=" mt-5 card-color rounded-lg">
          <v-card-title>
            <v-avatar tile color="#2f3861" class="rounded-lg">
              <v-icon dark> mdi-cart </v-icon>
            </v-avatar>
            <h4 class=" white--text">
              Productos Encargados

            </h4>
            <h5 v-if="productosPendientes.length >=1">Estos son tus productos pendientes <br> por recojer</h5>
              <h5 v-else>Aun no tienes productos pendientes por recojer :( </h5>
          </v-card-title>
          <v-card-text>
            <v-row v-for="(producto, i) in productosPendientes" :key="i">

              <v-col class="white--text ml-3">
               <v-row>
                <v-col> <h2 class="blue--text">{{producto.nombre}}</h2>
                  <h4>Encargado el: {{producto.fecha}}</h4>
                  <h3>
                    Precio:  <span class="green--text">{{producto.precio}}</span>
                    Cantidad: {{producto.producto_cantidad}}
                    Subtotal: <span class="green--text">{{producto.sub_total}}</span>
                  </h3></v-col>

                  <v-col class="text-center mt-5">
                    <h1 class="blue--text">Estado Pago: <br>
                        <h4 class="orange--text">{{ producto.metodo_pago }}</h4>
                    </h1>
                  </v-col>
               </v-row>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card width="100%" class="card-color mt-5 rounded-lg">
          <v-card-title>
            <v-menu

            rounded="xl"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="datosFecha"


            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-avatar color="#2f3861" class=" rounded-lg" v-bind="attrs"
                v-on="on">
                <v-btn  text dark>
                  <v-icon  dark>mdi-calendar</v-icon>
                </v-btn>
             </v-avatar>

            </template>
            <v-date-picker
              :events="arrayEvents"
              event-color="green lighten-1"
              v-model="datosFecha"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                :disabled="!datosFecha"
                text
                color="primary"
                @click="verProductosFecha(datosFecha)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
            <h4 class="ml-5 white--text">
              Historial <br />
              <h5>Historial de Compras</h5>
            </h4>
            <v-spacer />

          </v-card-title>
          <v-card-text v-if="productos.length >=1">
            <v-row v-for="(producto,i) in productos" :key="i">

              <v-col class="white--text ml-3">
                <v-row>
                 <v-col > <h2 class="blue--text">{{producto.nombre}}</h2>
                   <h4>Encargado el: {{producto.fecha}}</h4>
                   <h3>
                    Precio:  <span class="green--text">{{producto.precio}}</span>
                    Cantidad: {{producto.producto_cantidad}}
                    Subtotal: <span class="green--text">{{producto.sub_total}}</span>
                  </h3></v-col>

                   <v-col class="text-center mt-5">
                     <h1 class="blue--text">Estado Pago: <br>
                         <span class="green--text">{{ producto.metodo_pago }}</span>
                     </h1>
                   </v-col>
                </v-row>

               </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else class="text-center white--text">
            <h2>Selecciona una fecha en el icono del calendario para ver tu historial</h2>
          </v-card-text>
        </v-card> -->
      </v-card>

      <v-btn
      v-if="productosPendientes.length >=1"
        class="floating-button color-qrcode"
        fab

         color="#f7d51b"
        @click="handleButtonClick"
      >
        <v-icon>mdi-qrcode</v-icon>
      </v-btn>
    </div>
    <v-dialog persistent width="auto" v-model="editarFoto">
      <v-card max-width="500px" class="mx-auto" rounded="xl">
        <v-card-actions>
          <v-card-title>Elige Una foto de tus archivos</v-card-title>
          <v-btn @click="editarFoto = false" fab x-small color="red" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            :disabled="!selectedFile"
            @click="cambiarFoto"
            fab
            x-small
            color="blue"
            dark
          >
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-text class="text-center">
          <v-avatar size="230">
            <img
              v-if="imagePreviewURL"
              :src="imagePreviewURL"
              style="
                width: 100%;
                border-top-left-radius: 22px !important;
                border-bottom-left-radius: 22px !important;
              "
            />
            <v-img v-else :src="url + datosUsuario.foto_perfil"></v-img>
          </v-avatar>
          <v-file-input
            class="mt-10"
            outlined
            rounded
            filled
            dense
            v-model="selectedFile"
            label="Selecciona una Imagen"
            required
            @change="onFileChange"
          ></v-file-input>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="600px" v-model="editarPerfil">
      <editar-perfil
        @actualizarCarta="obtenerAcceso"
        @cerrarVentana="editarPerfil = false"
      ></editar-perfil>
    </v-dialog>


      <v-dialog persistent width="auto" v-model="dialoqrcode">
          <qr-code @cerrarQrCode="dialoqrcode=false"></qr-code>
      </v-dialog>


  </div>
</template>

  <script>
import axios from "axios";
import EditarPerfil from "../components/EditarPerfil.vue";
import moment from "moment";
import QrCode from './QrCode.vue';
import Swal from 'sweetalert2';
export default {
  components: { EditarPerfil, QrCode },

  data: () => ({
    dialog:true,
    url: "",
    drawer: true,
    button_name: "",
    items: [
      { title: "Inicio", icon: "mdi-home", color: "green", to: "/" },

    ],
    datosUsuario: [],
    selectedFile: null,
    imagePreviewURL: null,
    editarFoto: false,
    editarPerfil: false,
    enviarDatos: [],
    menu: false,
    menuEntrega: false,
    menuEntregaMobile: false,
    arrayEvents: [],
    datosFecha: "",
    fecha_entrega:'',
    dialoqrcode:false,
    productos: [],
    productosPendientes:[],
    allProductos: [],
    comprobantes: [],
  }),
  computed: {
    fechaActual() {
      const fecha = moment().format("MM-DD-YYYY");
      return fecha;
    },
    sumall() {
      const data = [];
      this.productosPendientes.forEach((element) => {
        data.push({ subtotal: element.precio * element.producto_cantidad });
      });
      const sumall = data
        .map((item) => item.subtotal)
        .reduce((prev, curr) => prev + curr, 0);

      return sumall;
    },
    sumallTotal() {
      const data = [];
      this.allProductos.forEach((element) => {
        data.push({ subtotal: element.precio * element.producto_cantidad });
      });
      const sumall = data
        .map((item) => item.subtotal)
        .reduce((prev, curr) => prev + curr, 0);

      return sumall;
    },
  },
  methods: {
    onFileChange(payload) {
      //const file = payload.target.files[0];
      const file = payload;
      const objeto = { datos: 1 };
      const prueba = { file, objeto };
      console.log(prueba);
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file);
      } else {
        this.imagePreviewURL = null;
      }
    },
    cambiarFoto() {
      const formData = new FormData();
      formData.append("image", this.selectedFile);
      formData.append("id", this.datosUsuario.id);
      //enviar la imagen al servidor con axios
      axios
        .post(`${this.$store.state.apiURL}actualizarFotoPerfil`, formData)
        .then((response) => {
          response;
          this.mostrarMensajeConfirmacion("IMAGEN ACTUALIZADA");

          this.editarFoto = false;
          this.obtenerAcceso();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    abrirEditar() {
      this.enviarDatos = this.datosUsuario;
      this.editarPerfil = true;
      this.dialog = false;
    },
    cerrarEditar() {
      this.editarPerfil = false;
      this.obtenerAcceso();
    },

    inicio() {
      this.$router.push("/");
    },
    cerrarSesion() {
      window.localStorage.clear();
      this.$router.push("/");
    },

    botonName(item) {
      this.button_name = item;
    },

    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
        this.editarPerfil = false;
      });
    },
    verProductos() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuarios`
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
        this.allProductos = datos.datos;
        this.productos.forEach((element) => {
          this.arrayEvents.push(element.fecha);
        });
      });
    },
    verProductosFecha(item) {
      this.$refs.menu.save(item);

      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,
        {
          fecha: item,
        },
        "POST"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
      });
    },
    seleccionarFechaEntrega(item) {
      this.$refs.menuEntrega.save(item);

   

      const fecha1 = new Date(moment().format("YYYY-MM-DD"));
      const fecha2 = new Date(moment(item));


const diferenciaEnMilisegundos = fecha2 - fecha1;


const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

if (diferenciaEnDias > 3) {
  this.mostrarMensajeAdvertencia('No puedes seleccionar un fecha mayor a 3 dias para recoger tu pedido, Los Dias Domingos no Estaremos disponibles')
} 

else if (diferenciaEnDias < 0) {
  this.mostrarMensajeAdvertencia('No puedes seleccionar un fecha Anterior a la de hoy para recoger tu pedido, Los Dias Domingos no Estaremos disponibles')

}
else {
  Swal.fire({
        title: "Estas Seguro?",
        text: `Deberas Asistir a nuestra tienda el: ${item} !`,
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
         cancelButtonText: "Cancelar",
        confirmButtonText: "Si, Confirmar!",
      }).then((result) => {
        if (result.isConfirmed) {
          const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}actualizarFechaEntrega`,
        {
          fecha_entrega: item,
        },
        "PUT"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
        this.obtenerAcceso()
      });
         
          Swal.fire("Fecha Actualizada!", "No Olvides asistir.", "success");
        }
      });
}

     
      
    },
    obtenerComprobantes() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarComprobanteClientes`
      );
      peticion.then(({ datos }) => {
        this.comprobantes = datos;
      });
    },
    handleButtonClick() {

      this.dialoqrcode = true;
    },

    verProductosFechaMounted() {
      const date = moment().format("YYYY-MM-DD");
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosUsuariosFecha`,
        {
          fecha: date,
        },
        "POST"
      );
      peticion.then((datos) => {
        //this.mostrarMensajeConfirmacion(mensaje);
        this.productos = datos.datos;
      });
    },
    productosSinRecoger() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}ListarPedidosSinRecojer`
      );
      peticion.then(({ datos }) => {
        this.productosPendientes = datos;
      });
    },
  },

  created() {
    this.obtenerAcceso();
    this.url = `${this.$store.state.imgURL}`;
    this.verProductos();
    this.obtenerComprobantes();
    this.verProductosFechaMounted();
    this.productosSinRecoger()
  },
};
</script>
  <style scoped>
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");

.fondo-main {
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #222a4d 0%, #0e1325 100%);

  height: 100%;
}

.color-card {
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #222a4d 0%, #0e1325 100%);
}
.color-qrcode {
  background-color:#f7d51b ;

}
.fondo-stats {
  color: white;
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #0e1325 0%, #22464d 100%);
}
.hover-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.v-avatar:hover .hover-button {
  display: flex;
}
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.3s;
  z-index: 999;
}

.fixed-button {
  opacity: 0;
}


.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
</style>
