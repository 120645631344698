<template>
  <div :class="{'fondo-green': step === 1, 'fondo-blue': step === 2, 'fondo-red': step ===3}">
    <v-container class="mt-n10 py-16" >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-card  class=" rounded-xl elevation-6 mt-10">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12 text-center">

                        <v-avatar tile size="100" class="mx-auto">
                          <v-img src="@/assets/images/meraLogo.png"></v-img>
                        </v-avatar>

                      <h3 class="text-center">Inicia Sesion en tu cuenta</h3>
                      <h5 class="text-center grey--text">
                        Inicia Sesion Para poder comprar y <br />añadir
                        productos a tu carrito de compras
                      </h5>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-text-field
                          :rules="rules.correo_electronico"
                          v-model="login.correo_electronico"
                            rounded
                            label="Email"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-16"
                          />
                          <v-text-field
                          v-model="login.contrasena"
                            rounded
                            label="Password"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            type="password"
                          />
                          <v-row>
                            <v-col cols="12" sm="7">
                              <v-checkbox
                                label="Recordarme"
                                class="mt-n1"
                                color="blue"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <a @click="step=3"  class="caption blue--text"
                                >Olvide la Contraseña :(</a
                              >
                            </v-col>
                          </v-row>
                          <v-btn
                          @click="iniciarSesion"
                          :disabled="!login.correo_electronico || !login.contrasena"
                          color="green" dark rounded width="100%"
                            >Continuar</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6" class="green rounded-bl-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h2 class="text-center">Aun No tienes una cuenta?</h2>
                        <h5 class="text-center">
                          Vamos a prepararlo todo para que pueda comenzar a
                          crear su primera cuenta<br />
                          Por favor continue
                        </h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark @click="step++"
                          >Crear Cuenta</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col cols="12" md="6" class="blue rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="mt-12 text-center white--text">

                        <v-avatar tile size="100" class="mx-auto">
                          <v-img src="@/assets/images/meraLogo.png"></v-img>
                        </v-avatar>
                        <h1 class="text-center">Ya tienes cuenta?</h1>
                        <h5 class="text-center">
                          Inicie sesión en su cuenta para que pueda continuar
                          <br />
                          disfrutando de nuestra tienda online
                        </h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark @click="step--"
                          >Iniciar Sesion</v-btn
                        >
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h2 class="text-center">
                        Regístrese para obtener una cuenta
                      </h2>
                      <h5 class="text-center grey--text">
                        Vamos a prepararlo todo para que pueda comenzar a crear
                        su cuenta <br />
                        Por favor rellena estos campos
                      </h5>

                      <v-stepper class="rounded-xl elevation-4" v-model="e1">
                        <v-stepper-header>
                          <v-stepper-step :complete="e1 > 1" step="1">
                            Paso 1
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step :complete="e1 > 2" step="2">
                            Paso 2
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step :complete="e1 > 3" step="3"> Paso 3</v-stepper-step>
                          <v-divider></v-divider>

                          <v-stepper-step step="4"> Verificacion</v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <v-row class="mb-3" align="center" justify="center">
                              <v-col cols="12" sm="8">
                                <v-form>
                                  <v-text-field
                                    :rules="rules.nombre"
                                    v-model="datosRegistro.nombre"
                                    rounded
                                    label="Nombre"
                                    outlined
                                    dense
                                    color="blue"
                                    autocomplete="false"
                                    class="mt-4"
                                  />

                                  <v-text-field
                                    :rules="rules.apellidoP"
                                    v-model="datosRegistro.apellidoP"
                                    rounded
                                    label="Apellido Paterno"
                                    outlined
                                    dense
                                    color="blue"
                                    autocomplete="false"
                                  />
                                  <v-text-field
                                    :rules="rules.apellidoM"
                                    v-model="datosRegistro.apellidoM"
                                    rounded
                                    label="Apellido Materno"
                                    outlined
                                    dense
                                    color="blue"
                                    autocomplete="false"
                                  />
                                </v-form>
                              </v-col>
                            </v-row>
                            <v-btn
                              :disabled="
                                !datosRegistro.nombre ||
                                !datosRegistro.apellidoP ||
                                !datosRegistro.apellidoM
                              "
                              color="blue"
                              class="mb-3 white--text"
                              rounded
                              @click="e1 = 2"
                            >
                              Continuar
                            </v-btn>
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <v-row class="mb-3" align="center" justify="center">
                              <v-col cols="12" sm="8">
                                    <v-select
                                    :items="dataPaises"
                                    item-text="pais"
                                    item-value="pais"
                                      v-model="datosRegistro.pais"
                                      rounded
                                      label="Pais"
                                      outlined
                                      dense
                                      color="blue"
                                      class="mt-4"
                                    />
                                
                        
                                <v-btn v-if="!datosRegistro.fechaNacimiento" @click="fechaNacimientoDialog=true" block color="blue" class="white--text" rounded>
                                Fecha Nacimiento
                                </v-btn>
                                <v-chip @click="fechaNacimientoDialog=true" class="mx-auto white--text" color="blue"  v-else>
                                  Fecha nacimiento :{{ datosRegistro.fechaNacimiento }} &nbsp; <v-icon>mdi-cake</v-icon>
                                </v-chip>
                              </v-col>
                            </v-row>

                            <v-btn
                            :disabled="!datosRegistro.pais 
                                || !datosRegistro.fechaNacimiento
                            "
                              color="blue"
                              class="white--text mb-3"
                              rounded
                              @click="e1 = 3"
                            >
                        
                              Continuar
                            </v-btn>

                          </v-stepper-content>
                            <v-dialog persistent class="mx-auto" width="auto" v-model="fechaNacimientoDialog">
                              
                              <v-card>
                                <v-card-title class="mx-auto text-center">
                                  Tu fecha de nacimiento es: <br>
                                  {{ datosRegistro.fechaNacimiento }}
                                </v-card-title>
                                 <v-date-picker
                              v-model="datosRegistro.fechaNacimiento"
                              
                            ></v-date-picker>
                                <v-card-actions>
                                  <v-btn @click="fechaNacimientoDialog=false" rounded text color="blue">
                                    Confirmar
                                  </v-btn>
                                </v-card-actions>
                          </v-card>
                             
                           
                          
                            </v-dialog>

                          <v-stepper-content step="3">
                            <v-row class="mb-3" align="center" justify="center">
                              <v-col cols="12" sm="8">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                    :rules="rules.correo_electronico"
                                      v-model="datosRegistro.correo_electronico"
                                      rounded
                                      label="Correo Electronico"
                                      outlined
                                      dense
                                      color="blue"
                                      autocomplete="false"
                                      class="mt-4"
                                    />
                                  </v-col>
                                </v-row>
                                <v-text-field
                                v-model="password"
                                  rounded
                                  label="Contraseña"
                                  outlined
                                  dense
                                  color="blue"
                                  autocomplete="false"
                                  type="password"
                                />
                                <v-text-field
                                  v-model="datosRegistro.contrasena"
                                  rounded
                                  label="Confirma tu contraseña"
                                  outlined
                                  dense
                                  color="blue"
                                  type="password"
                                
                                />
                                <v-row>
                                  <v-col cols="12" sm="7">
                                    <v-checkbox
                                  
                                    v-model="acceptTerms"
                                      label="Acepto los terminos y condiciones"
                                      class="mt-n1"
                                      color="blue"
                                    >
                                    </v-checkbox>
                                 
                                  </v-col>
                                  <v-col cols="12" sm="5">
                                    <v-btn
                                      @click="terminosCondiciones = true"
                                      text
                                      block
                                      rounded
                                      class="caption blue--text ml-n4"
                                      >Ver Terminos <br />
                                      y Condiciones</v-btn
                                    >
                                  </v-col>
                                </v-row>
                                <v-btn
                                :disabled="!datosRegistro.correo_electronico || !datosRegistro.contrasena || !password || acceptTerms == false"
                                  @click="crearUsuario"
                                  color="blue"
                                  dark
                                  block
                                  rounded
                                  >Registrarse</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-stepper-content>


                          <v-stepper-content step="4">
                            <v-row class="mb-3" align="center" justify="center">
                              <v-col cols="12" sm="8">
                                <v-row>
                                  <v-col>
                                    <h2>Hemos enviado un codigo de verificacion a tu correo!...</h2>
                                    <v-text-field
                                    persistent-hint
                                    hint="Ingresa el codigo de Verificacion"
                                    v-model="datosRegistro.token"
                                    rounded
                                    label="Codigo de Verificación"
                                    outlined
                                    dense
                                    color="blue"
                                    autocomplete="false"
                                    class="mt-4"
                                  />
                               
                                  </v-col>
                                </v-row>

                                <v-btn
                                :disabled="!datosRegistro.token"
                                  @click="activarUsuario(datosRegistro.token)"
                                  color="blue"
                                  dark
                                  block
                                  rounded
                                  >Registrarse</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-stepper-content>

                        </v-stepper-items>
                      </v-stepper>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="3">
                <v-row>
                  <v-col cols="12" md="6" class="red rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="mt-12 white--text text-center">

                        <v-avatar tile size="100" class="mx-auto">
                          <v-img src="@/assets/images/meraLogo.png"></v-img>
                        </v-avatar>
                        <h3 class="text-center">Regresar...</h3>
                        <h4 class="text-center">
                          Vuelva e inicie sesión en su cuenta para que pueda continuar
                          <br />
                          disfrutando de nuestra tienda online.
                        </h4>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark @click="step=1"
                          >Iniciar Sesion</v-btn
                        >
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h4 class="text-center">
                        Por favor complete todos los campos.
                      </h4>
                      <h5 class="text-center grey--text">
                        Vamos a prepararlo todo para que pueda comenzar a recuperar
                        su cuenta <br />
                        lo antes posible.
                      </h5>
                    </v-card-text>
                    <v-card-text class="text-center">
                      <v-window v-model="forgotPassword">
                          <v-window-item :value="1">
                            <p>Enviaremos un codigo de verificacion a este correo</p>
                            <v-text-field
                            :rules="rules.correo_electronico"
                            v-model="dataForgotPassword.correo_electronico" rounded outlined class="px-16" label="Ingresa tu email"/>
                              <div class="text-right px-16">
                                <v-btn 
                                @click="sendCodeMail"
                                :disabled="!dataForgotPassword.correo_electronico" color="red" dark rounded class="mb-5">
                                  Continuar                                
                                </v-btn>
                              </div>
                          </v-window-item>
                          <v-window-item :value="2">
                              <p>Ingresa el codigo que se te envio.</p>
                              <v-otp-input
                              
                              v-model="dataForgotPassword.codeVerification"
                              class="px-16 "
                              length="5"
                              type="number"
                            />
                              <h4>El codigo expira en 15 minutos</h4>
                              
                              <div class="px-16 text-right">
                                <v-btn 
                                @click="verifyCode"
                                :disabled="!dataForgotPassword.codeVerification || dataForgotPassword.codeVerification.length <5"
                                color="red" class="mb-5 mt-10" dark rounded >Verificar</v-btn>
                              </div>
                          </v-window-item>
                          <v-window-item :value="3">
                              <div class="px-16">
                                  <p>Introduce tus nuevas claves</p>
                                <v-text-field
                                v-model="newPassword"
                                @click:append="show1 = !show1"
                                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-lock-outline'"
                                :type="show1 ? 'text' : 'password'"
                                label="Nueva Contraseña"
                                rounded outlined
                                />
                                <v-text-field 
                                v-model="dataForgotPassword.newPassword"
                                @click:append="show1 = !show1"
                                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-lock-outline'"
                                :type="show1 ? 'text' : 'password'"
                                label="Nueva Contraseña"
                                rounded outlined
                             
                                />
                                <div class="text-right">
                                    <v-btn @click="sendNewPassword" color="red"  rounded dark class="mb-5">
                                      Confirmar
                                    </v-btn>
                                </div>
                              </div>

                          </v-window-item>
                      </v-window>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog class="rounded-xl" v-model="terminosCondiciones" width="500">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5 grey lighten-2">
          Terminos y Condiciones
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded color="blue" text @click="terminosCondiciones = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
    v-model="inventarios"
    persistent
    transition="dialog-top-transition"
    max-width="600"
  >
    <v-card  rounded="xl">
      <v-toolbar color="blue" dark
        >Detectamos que eres un administrador! 
        
      
        </v-toolbar
      >
      <v-card-text>
        <div class="text-h2 mt-10">Da click en Continuar!</div>
        <div class="text-h7 pa-12">
          ¡Te damos la Bienvenida!. 
          Ingresa al Sistema de Inventarios, para poder administrarlo
          y vender tus componentes.
          Lamentamos las molestias.
          ! <br>



        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        
        <v-btn color="blue" text
        target="_blank"
        href="http://ingenieriamera.com/inventarios"
          >Continuar
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
   

  </div>
</template>
  

<script>
export default {
  data: () => ({
    inventarios: false,
    newPassword:'',
    show1: false,
    dataForgotPassword:{},
    forgotPassword:1,
    acceptTerms: false,
    login:{},
    password: '',
    fechaNacimientoDialog: false,
    terminosCondiciones: false,
    e1: 1,
    step: 1,
    datosValidacion:[],
    datosRegistro: {},
    mexicanStates:[],
    dataPaises:[{pais: 'México'},{pais: 'Otro'}],
    rules: {
      nombre: [(val) => (val || "").length > 0 || "Este campo es Obligatorio"],
      apellidoP: [
        (val) => (val || "").length > 0 || "Este campo es Obligatorio",
      ],
      apellidoM: [
        (val) => (val || "").length > 0 || "Este campo es Obligatorio",
      ],
      pais: [
        (val) => (val || "").length > 0 || "Este campo es Obligatorio",
      ],
      estado: [
        (val) => (val || "").length > 0 || "Este campo es Obligatorio",
      ],
      edad: [
        (val) => (val || "").length > 0 || "Este campo es Obligatorio",
        (val) => /^[0-9]+$/.test(val) || 'El campo debe contener solo números'
      ],
      correo_electronico: [
        (value) => !!value || 'El correo electrónico es requerido.',
        (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value) || 'Ingrese un correo electrónico válido.';
        },
      ],
      datosUsuario:[],
      datosUsuarioEnviar:[],
     
    },
  }),
  methods: {

      sendCodeMail(){
        const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}sendCodeEmailClientMera`,
        this.dataForgotPassword,
        "PUT"
      );
      peticion.then((datos) => {
        if(datos.datos){
          this.forgotPassword = 2;
          this.mostrarMensajeConfirmacion('Hemos Enviado un codigo a tu correo')
        }else{
          console.log('Invalid');
        }
        

      });
      },
      verifyCode(){
        const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}verifyCodeClientMera`,
        this.dataForgotPassword,
        "POST"
      );
      peticion.then(({datos}) => {
          
          this.dataForgotPassword.id= datos.id
          this.forgotPassword =3;
          this.mostrarMensajeConfirmacion('Puedes Restablecer tu contraseña')
      });
      },

      sendNewPassword(){
        if (this.newPassword === this.dataForgotPassword.newPassword){
          
        const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}changePasswordClientMera`,
        this.dataForgotPassword,
        "POST"
      );
      peticion.then(({data}) => {
          
          data
          this.mostrarMensajeConfirmacion('Tu contraseña fue cambiada correctamente')
          this.step =1;
      });
      }else{
          this.mostrarMensajeAdvertencia('Las contraseñas no coinciden')
      }
















        
      },





    inicio() {
      this.$router.push("/");
    },
   
      activarUsuario(token){
       
        const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}activacionUsuariosTienda`,
        {
          id: this.datosUsuarioEnviar,
          token: token
        },
        'PUT');
      peticion.then((datos) => {
        if (datos.datos){
          this.mostrarMensajeConfirmacion('Usuario Activado')
        this.step = 1;
        }else{
          this.mostrarMensajeAdvertencia('Codigo Incorrecto')
        }
      
      });
      },
    crearUsuario() {
      if (this.password === this.datosRegistro.contrasena){
        const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}crearUsuariosTienda`,
        this.datosRegistro,
        "POST"
      );
      peticion.then((datos) => {
       
        this.datosUsuarioEnviar = datos.datos;
        this.mostrarMensajeConfirmacion("Enviamos un correo de confirmacion a tu correo");
        this.e1=4;
      });
      }else{
          this.mostrarMensajeAdvertencia('Las contraseñas no coinciden')
      }
    },
  
    iniciarSesion() {
                const peticion = this.enviarPeticionAPI(`${this.$store.state.apiURL}loginTienda`, this.login, "POST");
                localStorage.clear();
                peticion.then((datos) => {
                    this.datosUsuario = datos.datos;

                      if(this.datosUsuario.access){
                        this.inventarios = true;





                      }else{
                        
  localStorage.setItem("token", this.datosUsuario.token);       
                    this.mostrarMensajeConfirmacion('Inicio de sesion correcto!')
                    this.$router.push('/')
                      }


                   

                  
                });

                console.log("Validation Successfully");
        },
  },
  created() {
  },

  props: {
    source: String,
  },
};
</script>






  <style scoped>
  .fondo-green{
    height: 100%;
    background-color: #F4D03F;
    background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
    
  }
  .fondo-blue{
    height: 100%;
    background-color: #0789d6;
    background-image: linear-gradient(160deg, #0789d6 0%, #80D0C7 100%);
    
  }
  .fondo-red{
    height: 100%;
    background-color: #ff000b;
    background-image: linear-gradient(147deg, #ff000b 0%, #ce29b0 74%);
    
  }
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
  



