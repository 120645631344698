import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GroupCategory from '../views/GroupCategory.vue'
import Modulos from '../viewsProducts/Modulos.vue'
import DesarrolloFabricacion from '../viewsProducts/DesarrolloFabricacion.vue'
import TarjetasDesarrollo from '../viewsProducts/TarjetasDesarrollo.vue'

import Login from '../views/Accounts.vue'
import Usuarios from '../components/Perfil.vue'
import ProductosPopulares from '../viewsProducts/ProductosPopulares.vue'
import BusquedaProductos from '../viewsProducts/BusquedaProductos.vue'



import EditarPerfilUsuario from '../viewsProducts/VistaEditarPerfil.vue'
import PedidosUsuarios from '../viewsProducts/VistaPedidos.vue'
import qrcode from '../components/QrCode.vue'
import support from '../components/Soporte.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  
  },
  {
    path: '/categorias',
    name: 'GroupCategory',
    component: GroupCategory,
   
    children:[
      {path: '/categorias/Modulos', component: Modulos},
      {path: '/categorias/DesarrolloFabricacion', component: DesarrolloFabricacion},
      {path: '/categorias/TarjetasDesarrollo', component: TarjetasDesarrollo},
      {path: '/categorias/ProductosTop', component: ProductosPopulares},
      {path: '/categorias/BusquedaProductos/:palabra', component: BusquedaProductos },

    ]
  },
  {
    path: '/account',
    name: 'account',
    component: Login,
    children:[
      {path: '/account/login', component: Login},
     
    ]
  },
  {
    path: '/users',
    name: 'users',
    component: Usuarios,
    beforeEnter: (to, from, next) => {
      if (!window.localStorage.token ) {
          next("/account");
      }
      next();
  },
    children:[
      {path: '/users/editarPerfilUsuario', component: EditarPerfilUsuario},
      {path: '/users/pedidosActivos', component: PedidosUsuarios},
      {path: '/users/qrcode', component: qrcode},
      {path: '/users/soporte', component: support },
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
