import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
    apiURL: `http://34.172.71.150:${process.env.VUE_APP_PORT}/api/`,
    imgURL: `http://34.172.71.150:${process.env.VUE_APP_PORT}/`
    },  
    actions: {
    

    },
    mutations:{

    }    
})
