<template>
  <div class="color-fondo">
    <br />
    <v-card class="mx-2 navbar-color mb-5" rounded="xl">
      <v-card-title>
        <v-avatar @click="$router.push('/')" tile size="80">
          <v-img src="@/assets/images/meraLogo.png"></v-img>
        </v-avatar>

        <div v-if="datosUsuario === 'No Loggeado'"></div>
        <v-btn
          v-else
          class="rounded-lg d-sm-block d-md-none"
          color="blue"
          style="background-color: #252e53"
          to="/users/editarPerfilUsuario"
          text
          dark
        >
          <v-icon>mdi-account</v-icon>
          Pefil
        </v-btn>

        <v-spacer />
        <div v-if="datosUsuario === 'No Loggeado'"></div>
        <v-badge
          v-else
          color="red  "
          overlap
          :content="cantidad"
          class="mr-8 mt-1"
        >
          <v-avatar rounded="lg" color="#252e53" size="50">
            <v-icon @click="abrirSideBar" color="blue"
              >fas fa-shopping-cart</v-icon
            >
          </v-avatar>
        </v-badge>

        <v-btn
          v-if="datosUsuario === 'No Loggeado'"
          to="/account/login"
          text
          color="green"
          class="d-none d-md-block"
          >Log <strong> in &nbsp;</strong>
          <v-icon x-small color="blue">fa-right-to-bracket</v-icon>
        </v-btn>
        <div class="d-none d-md-block" v-else>
          <v-avatar size="40" class="ml-2">
            <v-img :src="url + datosUsuario.foto_perfil"></v-img>
          </v-avatar>

          <v-menu max-width="200px" open-on-hover rounded="xl" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                class="text-overline"
                text
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <strong>
                  {{
                    datosUsuario.nombre + " " + datosUsuario.apellidoP
                  }}</strong
                >
              </v-btn>
            </template>
            <v-card shaped>
              <v-list dense>
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="url + datosUsuario.foto_perfil" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      datosUsuario.nombre + " " + datosUsuario.apellidoP
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Bienvenido!</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/users/editarPerfilUsuario">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Mi perfil</v-list-item-title>
                </v-list-item>
                <v-list-item to="/users/pedidosActivos" color="red">
                  <v-list-item-icon>
                    <v-icon>mdi-bag-personal-tag-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Pedidos</v-list-item-title>
                </v-list-item>
                <v-list-item to="/users/pedidosActivos" color="red">
                  <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Historial Compras</v-list-item-title>
                </v-list-item>
                <v-list-item to="/users/soporte" color="red">
                  <v-list-item-icon>
                    <v-icon>mdi-lifebuoy</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Soporte</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="cerrarSesion" link>
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-card-title>
      <router-view @mostrarMensaje="probarBoton"></router-view>
    </v-card>

    <right-side-bar
    @cerrarCarrito="cerrarCarrito"
      :drawer="drawer"
      :cantidad="cantidad"
      @actualizarCantidad="actualizarCantidad"
    />
  </div>
</template>

<script>
import RightSideBar from "../components/RightSideBar.vue";
export default {
  components: {
    RightSideBar,
  },
  data: () => ({
    drawer: false,
    cantidad: null,

    url: "",
    vista: true,
    datosUsuario: [],
  }),
  watch: {
    cantidad(nuevoValor) {
      console.log(
        'La propiedad "mensaje" ha cambiado. Nuevo valor:',
        nuevoValor
      );
    },
  },
  computed: {},
  methods: {
    cerrarCarrito(){
          this.drawer = !this.drawer;
    },
    actualizarCantidad() {
      this.cantidadCarrito();
    },
    abrirSideBar() {
      this.drawer = !this.drawer;
    },
    cerrarSesion() {
      window.localStorage.clear();
      location.reload();
    },
    probarBoton() {
      this.cantidadCarrito();
    },
    cantidadCarrito() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.cantidad = datos.datos.length;
      });
    },
    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
  },
  created() {
    this.cantidadCarrito();
    this.url = `${this.$store.state.imgURL}`;
    this.obtenerAcceso();
  },
};
</script>

<style scoped>
.color-fondo {
  width: 100%;
  height: 100%;
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #222a4d 0%, #0e1325 100%);
}
.navbar-color {
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);
}
</style>
