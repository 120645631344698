<template>
  <div class="mx-auto" style="max-width: 180vh">
  
    <v-bottom-navigation
   
      class="rounded-xl"
      v-model="value"
      :style="{background: color}"
      dark
      shift
    >
      <v-btn @click="onboarding=0">
        <span>Electronicos</span>

        <v-icon>mdi-lightning-bolt</v-icon>
      </v-btn>

      <v-btn @click="onboarding=1">
        <span>Robotica Educativa</span>

        <v-icon>mdi-robot-angry</v-icon>
      </v-btn>

      <v-btn @click="onboarding=2">
        <span>Imprecion 3D</span>

        <v-icon>mdi-printer-3d</v-icon>
      </v-btn>

      <v-btn @click="onboarding=3">
        <span>Cortes Laser</span>

        <v-icon>mdi-laser-pointer</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-window class="rounded-xl mt-5" v-model="onboarding">
      <v-window-item  v-for="(show, index) in items" :key="index" >
        <v-card dark class="color-barraSuperior" elevation="4"   height="450">
          <v-row    class="d-none d-md-block  py-10" >
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-img   class=" rounded-xl mt-2"  :src="url+show.src">

                </v-img>
              </v-col>
              <v-col class="text-center" style="margin-top: 5rem;"><h1>{{show.titulo}}</h1> <br>
                <span >{{show.subTitulo}}</span></v-col>
            </v-row>
          </v-card-text>
          </v-row>

            <v-row class="d-sm-block d-md-none ">
              <v-card-text class="text-center py-5">
                <h1>{{show.titulo}}</h1> <br>
                <span >{{show.subTitulo}}</span>
                <br>
                <v-img  class=" rounded-xl mt-2"  :src="url+show.src">

                </v-img>
              </v-card-text>
            </v-row>

        </v-card>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>


export default {
    data() {
        return {
          url:'',
            onboarding:0,
            value: 0,
            items: [
                {
                    src: "componentesElectronicos.jpeg",
                    titulo: "Componentes Electronicos",
                    subTitulo: "Encuentra los mejores precios en componentes electronicos del Norte del Estado de Mexico asi como asesorias para tus proyectos escolares o profecionales.",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                },
                {
                    src: "roboticaEducativa.jpeg",
                    titulo: "Robotica Educativa",
                    subTitulo: "Conoce los robots que han desarrollado nuestro equipo de Ingenieros. Ideales para aprender los principios de la robotica con tarjetas de codigo abierto. ",
                    description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    src: "imprecion3D.jpeg",
                    titulo: "Imprecion 3D",
                    subTitulo: "Contamos con el servicio de imprecion 3D de hasta 30x30x30 cm en material PLA,ABS,PET entre otros. Tambien contamos con el servicio de modelado de piezas para su imprecion 3D.",
                    description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                },
                {
                    src: "corteLaser.jpeg",
                    titulo: "Cortes Laser",
                    subTitulo: "Obtén cortes precisos y detallados en tus materiales con nuestro servicio de cortes láser de última generación. ¡Personaliza tus diseños y lleva tus proyectos al siguiente nivel con nosotros!",
                    description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                },
            ],
        };
    },
    computed: {
      color(){
        switch(this.value) {
  case 0:
    return 'linear-gradient( 91deg,  rgba(72,154,78,1) 5.2%, rgba(251,206,70,1) 95.9% )'
  
  case 1:
    return 'linear-gradient( 270.3deg,  rgba(84,212,228,1) 0.2%, rgba(68,36,164,1) 100% )'
  case 2:
    return 'linear-gradient( 109.6deg,  rgba(103,30,117,1) 11.2%, rgba(252,76,2,1) 91.1% )'
  case 3:
    return 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% )'
  
  default:
   return 'blue'
  
}
       
      }
    },
    created(){
      this.url = `${this.$store.state.imgURL}`; 
    
    },
    watch: {
   
  },
    methods:{
      
    }

   
};
</script>

<style scoped>
.color-barraSuperior {
  background-color: #252e53;
}




</style>