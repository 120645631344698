<template>
  <v-app class="card-color">
    <Navbar class="elevation-5" :key="cantidad" />
    <div v-if="datosUsuario === 'No Loggeado'">
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card  rounded="xl">
          <v-toolbar color="green" dark
            >Bienvenido a Nuestra Tienda Online 
            
          
            </v-toolbar
          >
          <v-card-text>
            <div class="text-h2 mt-10">Antes de Continuar!</div>
            <div class="text-h7 pa-12">
              ¡Te damos la Bienvenida!. Crear una cuenta en nuestro sistema es
              fácil y rápido. Regístrate hoy mismo para acceder a todas las
              funcionalidades que tenemos para ofrecerte, desde una experiencia
              personalizada hasta descuentos exclusivos en nuestros productos y
              servicios. No esperes más para formar parte de nuestra comunidad,
              ¡crea tu cuenta ahora y comienza a disfrutar de todos los
              beneficios! <br>

            


            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Mas Tarde</v-btn>
            <v-btn color="green" text to="/account/login"
              >Crear Cuenta
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card
            flat
            color=""
            max-width="1400px"
            class="color-barraSuperior mx-auto rounded-xl mt-8 "
          >
            <div class="text-left px-16">
              <v-row>
                <v-col>
                  <h1 style="font-size: 70px; color: white;"><span class="d-none d-lg-block">Ingenieria</span> Mera.</h1>
                  <h1 class="grey--text">
                    La Mejor Forma de comprar tus productos
                  </h1></v-col
                >
                <v-col cols="4" class="d-none d-md-block  mt-10">
                  <span class="white--text">
                    Necesitas Ayuda?

                    <br />
                    <h6 class="blue--text">Contactanos</h6>
                  </span>
                  <br />
                  <span
                  class="white--text"
                    >Visita nuestra Tienda <br />
                    <h6 class="blue--text">Encuentra Nuestra Tienda</h6>
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <Category class="mb-5" />
              </v-row>
            </div>
            <!-- COMPONENTE CATEGORIA -->
          </v-card>
          <!-- PRODUCTOS POPULARES -->

          <!-- <Product /> -->
          <v-card flat max-width="1200px" class=" mx-auto color-barraSuperior rounded-xl mt-8">
            <v-toolbar flat color="transparent" class="mb-4 px-16">
              <div class="text-overline centrar-div">
                <v-toolbar-title class="text-h6 text-left mt-5 white--text"
                  ><strong class="blue--text">Top Ventas:</strong> 
                  
                  Los Productos Más Populares del Momento
                </v-toolbar-title>
              </div>
              <v-spacer></v-spacer>
            </v-toolbar>

            <Pack @actualizarCantidad="probarBoton" />
          </v-card>

          <v-card
            max-height=""
            max-width="1200px"
            flat
            class="color-barraSuperior mx-auto rounded-xl mt-8 px-16"
          >
            <v-toolbar flat color="transparent" class="mt-5">
              <div class="text-overline centrar-div">
                <v-toolbar-title class="text-h6 text-left white--text"
                  >Te ayudamos.<strong class="blue--text">
                    Cuando, como y donde quieras.</strong
                  ></v-toolbar-title
                >
              </div>
              <v-spacer></v-spacer>
            </v-toolbar>
            <Carousel />
          </v-card>
      
        </v-col>
      </v-row>
    </v-container>
    <Footer  />
  </v-app>
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Category from "../components/Category.vue";
// import Product from "../components/Product.vue";
import Pack from "../components/Pack.vue";
// import Client from "../components/Client.vue";
import Carousel from "../components/Carousel.vue";

export default {
  name: "Home",
  data: () => ({
    cantidad: null,
    dialog: true,
    url: "",
    toggle_exclusive: 1,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    datosUsuario: [],
  }),

  methods: {
    probarBoton() {
      this.cantidadCarrito();
    
    },
    cantidadCarrito() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}listarCarrito`
      );
      peticion.then((datos) => {
        this.cantidad = datos.datos.length;
      });
    },

    obtenerAcceso() {
      const peticion = this.enviarPeticionAPI(
        `${this.$store.state.apiURL}accesoUsuarioTienda`
      );
      peticion.then((datos) => {
        this.datosUsuario = datos.datos;
      });
    },
  },
  created() {
    this.url = `${this.$store.state.imgURL}`; 
    this.obtenerAcceso();
  },
  components: {
    Navbar,
    Footer,
    Category,
    // Product,
    Pack,
    // Client,
    Carousel,
  },
};
</script>
<style scoped >
.texto-borde {
  font-size: 50px;
  -webkit-text-stroke: 2px black;
  color: transparent;
}
.marginLeft {
  margin-left: -90px;
}
.mtop {
  margin-top: 100px;
}
.mbottom {
  margin-bottom: 100px;
}
.v-card.borderme {
  border: 2px solid green !important;
}
.v-card.borderout {
  border: 1px solid #d5f0db !important;
}
.v-btn:not(.v-btn--round).v-size--default.add {
  min-width: 0px !important;
}
.theme--light.v-sheet--outlined.mobile {
  border: 2px solid black !important;
}
@media only screen and (max-width: 600px) {
  h2.title1 {
    font-size: 15px;
  }
  h2.title2 {
    font-size: 15px;
  }
  .top {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .top {
    margin-top: 70px;
  }
}
@media only screen and (min-width: 768px) {
  .top {
    margin-top: 120px;
  }
}
.centrar-div {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.card-color{
  background-color: #222a4d;
  background-image: linear-gradient(135deg, #051b3e 0%, #0e1325 100%);

}
.color-barraSuperior {
  background-color: #252e53;
}
</style>
